import React from "react";
import LinkShareButton from "../post-share/link-share-button";
import { APP_URL } from "../../constants/api.constants";

const GroupBuyCard = ({ data, navigate }) => {
  const gotoGroupBuy = (item) => {
    navigate("/group-buy/" + item.unique_id);
  };

  return (
    <div>
      <p className="title"> Group Buys ({data.length})</p>
      <div className="gb-container">
        {data.map((groupBuy) => (
          <div className="group-buy-card">
            <div onClick={() => gotoGroupBuy(groupBuy)}>
              {groupBuy.last_day_to_enroll && (
                <div className="gb-date">{groupBuy.last_day_to_enroll}</div>
              )}

              <img
                className={
                  groupBuy.image ? "group-buy-card-image" : "gb-no-image"
                }
                src={
                  groupBuy.image
                    ? groupBuy.image
                    : require("./../../assets/icons/no-photos.png")
                }
              />
              <p className="text-color gb-title"> {groupBuy.name} </p>
              <p className="gb-member-count">
                {" "}
                Members Joined : {groupBuy.total_members_count}
              </p>
              <p className="gb-admin">
                GB Admin : {groupBuy.group_buy_admin?.name}
              </p>
              {/* <p className="gb-last-day">Enroll Before : 25NOV24</p> */}
            </div>

            <div className="gb-share">
              <LinkShareButton
                url={APP_URL + "group-buy/" + groupBuy.unique_id}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GroupBuyCard;
