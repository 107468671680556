import React from "react";

import PageWrapperComponent from "../../components/page-wrapper/page-wrapper.component";
import AccountHeaderComponent from "../../components/account-header/account-header.component";
import { createApi, createCategoryAPI } from "./group-by.service";
import { useNavigate } from "react-router-dom";
import FormBuilderComponent from "../../components/form-builder/form-builder";
import { getGroupByForm } from "./form.constant";

const AddGroupByComponent = () => {
  const navigate = useNavigate();

  const gotoScreen = (url) => {
    navigate(url);
  };

  const handleSubmit = async (values) => {
    const resp = await createApi(values);
    if (resp.status) {
      gotoScreen("/group-buy"); // Use the gotoScreen function to navigate
    }
  };

  return (
    <PageWrapperComponent>
      <div className="page-container">
        <AccountHeaderComponent name="Add Group Buy" backRoute="/group-buy" />

        <div>
          <div style={{ paddingTop: 70, paddingLeft: 12, paddingRight: 12 }}>
            <FormBuilderComponent
              onSubmit={handleSubmit}
              inputs={getGroupByForm({})}
            />
          </div>
        </div>
      </div>
    </PageWrapperComponent>
  );
};

export default AddGroupByComponent;
