import React from "react";

const FormInputSelectComponent = ({
  label,
  name,
  id,
  value,
  placeholder,
  classnames,
  icon,
  error,
  onChange,
  options = [],
  isRequired,
}) => {
  const handleChange = (e) => {
    console.log({ e });
    if (onChange) onChange(name, e.target.value);
  };

  return (
    <div className={`form-input-container ${error ? "error" : ""}`}>
      {label && (
        <p className="no-margin form-input-label">
          {" "}
          {label} <span className="color-red">{isRequired ? "*" : ""}</span>
        </p>
      )}
      <select
        className={classnames || "form-input-select"}
        id={id}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
      >
        {options.map((option) => (
          <option value={option.value}> {option.name} </option>
        ))}
      </select>

      {error && <span className="form-input-error"> {error} </span>}

      {icon && <span className="form-input-icon"> {icon} </span>}
    </div>
  );
};

export default FormInputSelectComponent;
