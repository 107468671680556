import React from "react";

const ButtonComponent = ({ text, onClick, className = "primary-button" }) => {
  const handleClick = () => {
    if (onClick) onClick();
  };
  return (
    <div onClick={handleClick} className={className}>
      <p> {text} </p>
    </div>
  );
};

export default ButtonComponent;
