import React from "react";

const SearchComponent = ({
  search,
  style,
  handleSearch,
  placeholder = "Search by Part, Name, Mobile or Location",
}) => {
  return (
    <div style={style} className="app-search">
      <input
        className="search-input"
        type="text"
        name="search"
        id="search"
        value={search}
        onChange={(e) => handleSearch(e.target.value)}
        placeholder={placeholder}
      />
    </div>
  );
};

export default SearchComponent;
