import React from "react";

const FormInputTextAreaComponent = ({
  label,
  type = "text",
  name,
  id,
  value,
  placeholder,
  classnames,
  icon,
  error,
  onChange,
  isRequired,
}) => {
  const handleChange = (e) => {
    // console.log({ e });
    if (onChange) onChange(name, e.target.value);
  };

  return (
    <div className="form-input-container">
      {label && (
        <p className="no-margin form-input-label">
          {label} <span className="color-red">{isRequired ? "*" : ""}</span>
        </p>
      )}
      {/* <input
        className={classnames || "search-input"}
        type={type}
        name={name}
        id={id}
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
      /> */}

      <textarea
        id={id}
        name={name}
        value={value}
        onChange={handleChange}
      ></textarea>

      {error && <span className="form-input-error"> {error} </span>}

      {icon && <span className="form-input-icon"> {icon} </span>}
    </div>
  );
};

export default FormInputTextAreaComponent;
