import React, { useEffect, useState } from "react";

import PageWrapperComponent from "../../components/page-wrapper/page-wrapper.component";
import AccountHeaderComponent from "../../components/account-header/account-header.component";
import { FaPlus } from "react-icons/fa";
import FloatingButtonComponent from "../../components/floating-icon/floating-icon.component";
import { useNavigate } from "react-router-dom";
import EmptyBoxComponent from "../../components/empty-box/empty-box";
import { deleteDataApi, getDataApi } from "./post.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import LoaderComponent from "../../components/loader/loader";
import SearchComponent from "../../components/search/search";
import { groupBy } from "../../utils/common";
// import
const PostComponent = () => {
  const navigate = useNavigate();
  const [all, setAll] = useState({ loading: true, search: "" });
  const [tableData, setTableData] = useState([]);

  const gotoScreen = (url) => {
    navigate(url);
  };
  let params = {
    orderBy: "created_at",
    sortBy: "desc",
    skip: 0,
    limit: 50,
  };
  const onload = () => {
    getData(params);
  };

  const getData = async (params) => {
    const data = await getDataApi(params);

    if (data.status) {
      setAll({ ...all, allTableData: data.data, loading: false });
      setTableData(data.data);
    } else {
      setAll({ ...all, loading: false });
    }
  };

  const handleSearch = (value) => {
    setAll({ ...all, search: value });

    if (!value) {
      setTableData(all.allTableData);
      return;
    }
    const results = all.allTableData.filter((post) =>
      post.title.toLowerCase().includes(value.toLowerCase())
    );

    const phoneResults = all.allTableData.filter((post) =>
      post.customer_details
        ? post.customer_details.phone
            .toLowerCase()
            .includes(value.toLowerCase())
        : false
    );

    const customerResults = all.allTableData.filter((post) =>
      post.customer_details
        ? post.customer_details.name.toLowerCase().includes(value.toLowerCase())
        : false
    );

    const locationResults = all.allTableData.filter((post) =>
      post.customer_details
        ? post.customer_details.location
            .toLowerCase()
            .includes(value.toLowerCase())
        : false
    );

    const searchRes = [
      ...results,
      ...phoneResults,
      ...customerResults,
      ...locationResults,
    ];

    const groupedResults = groupBy(searchRes, "id");

    setTableData(groupedResults);
  };

  useEffect(() => {
    onload();
  }, []);

  const handleEdit = (post) => {
    // gotoScreen("/post/edit");
    navigate("/post/edit", { state: post });
  };
  const handleDeleteClick = (post) => {
    console.log(post);

    Swal.fire({
      title: "Delete post!",
      text: `Are you sure you want to delete the ${post.name} post?`,
      icon: "info",
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
      showCancelButton: true,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        handleDeleteSubmit(post.id);
      } else if (result.isDismissed) {
        console.log("Dismissed ");
      }
    });
  };

  const handleDeleteSubmit = async (id) => {
    const respose = await deleteDataApi([id]);
    onload();
  };

  return (
    <PageWrapperComponent>
      <div className="page-container ">
        <AccountHeaderComponent name="Manage Post" backRoute="/account" />

        <div className="account-content">
          <div className="container">
            <SearchComponent
              style={{ marginTop: 10 }}
              search={all.search}
              handleSearch={handleSearch}
            />
          </div>

          {!all.loading && tableData.length === 0 && (
            <div style={{ marginTop: 10 }} className="container t-a-c">
              <EmptyBoxComponent />
            </div>
          )}

          {all.loading && (
            <div className="t-a-c">
              <LoaderComponent />
            </div>
          )}

          <div style={{ paddingBottom: 85 }}>
            {tableData.map((item, i) => (
              <div
                key={i}
                style={{
                  margin: "10px",
                  border: "1px solid #ccc",
                  borderRadius: "10px",
                  padding: "15px",
                  display: "flex",
                  alignItems: "center",
                  boxSizing: "border-box",
                  backgroundColor: "#fff",
                }}
              >
                <div style={{ display: "flex", width: "90%" }}>
                  <div style={{}}>
                    <div className="grid-product-item ">
                      <div>
                        {item?.images?.length > 0 && (
                          <img
                            className="grid-product-item-image-post"
                            src={item?.images[0]?.image}
                          />
                        )}

                        {!item.images && (
                          <div className="t-a-c">
                            <img
                              className="grid-product-item-image-post"
                              src={require("./../../assets/icons/no-photos.png")}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="post-price-container ">
                    <div>
                      <span className="post-title">{item.title}</span>
                    </div>

                    {/* <p className="no-margin post-desc"> {item.description}</p> */}
                    <p className="no-margin post-price"> ₹ {item.price}</p>

                    <p className="no-margin post-location">
                      {item?.customer_details?.name}
                    </p>

                    <p className="no-margin post-location">
                      {item?.customer_details?.phone}
                    </p>

                    <p className="no-margin post-location">
                      {item?.customer_details?.address}
                    </p>

                    <p className="no-margin post-category2"> {item.category}</p>
                  </div>
                </div>

                <div style={{ width: "10%" }}>
                  <div>
                    <div
                      style={{
                        marginBottom: "30px",
                        marginLeft: "15px",
                        textAlign: "right",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faEdit}
                        style={{ cursor: "pointer", color: "#7c77fb" }}
                        onClick={() => handleEdit(item)}
                      />
                    </div>

                    <div style={{ textAlign: "right" }}>
                      <FontAwesomeIcon
                        icon={faTrashCan}
                        style={{ cursor: "pointer", color: "#dc3545" }}
                        onClick={() => handleDeleteClick(item)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <FloatingButtonComponent
            icon={<FaPlus />}
            onClick={() => gotoScreen("/post/add")}
            position={{ bottom: "30px", right: "30px" }}
          />
        </div>
      </div>
    </PageWrapperComponent>
  );
};

export default PostComponent;
