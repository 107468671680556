import { API } from "../../constants/api.constants";
import { store } from "../../store";
import { checkForExpiredToken } from "../post/post.service";

const {
  GROUP_BUY,
  CUSTOMER_REGISTER,
  CUSTOMER_REGISTER_VERIFY,
  GROUP_BUY_JOIN,
  GROUP_BUY_LEAVE,
  GROUP_BUY_MEMBER_PAYMENT_DETAILS,
  GROUP_BUY_MEMBER_SAVE_PAYMENT_DETAILS,
  GROUP_BUY_JOIN_BY_ADMIN,
  GROUP_BUY_MEMBER_EDIT_PAYMENT_DETAILS,
} = API;

export const leaveGroupBuyAPI = async (id, memberId = "") => {
  return await fetch(GROUP_BUY_LEAVE + id + memberId, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      token: store.getState().app.token,
    },
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status === true)
        return {
          data: data.data,
          message: data.message,
          status: data.status,
        };
      else {
        checkForExpiredToken(data.message);
        return { status: false, message: data.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong from login api",
        error,
      };
    });
};

export const joinGroupBuyAPI = async (id) => {
  return await fetch(GROUP_BUY_JOIN + id, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      token: store.getState().app.token,
    },
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status === true)
        return {
          data: data.data,
          message: data.message,
          status: data.status,
        };
      else {
        checkForExpiredToken(data.message);
        return { status: false, message: data.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong from login api",
        error,
      };
    });
};

export const memberRegistrationVerifyAPI = async (payload) => {
  return await fetch(CUSTOMER_REGISTER_VERIFY, {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status === true)
        return {
          data: data.data,
          status: data.status,
        };
      else {
        checkForExpiredToken(data.message);
        return { status: false, message: data.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong from login api",
        error,
      };
    });
};

export const memberRegistrationAPI = async (payload) => {
  return await fetch(CUSTOMER_REGISTER, {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status === true)
        return {
          data: data.data,
          status: data.status,
        };
      else {
        checkForExpiredToken(data.message);
        return { status: false, message: data.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong from login api",
        error,
      };
    });
};

export const getGroupBuyDataAPI = async (id) => {
  const token = store.getState().app.token;
  return await fetch(GROUP_BUY + id, {
    method: "GET",
    headers: token
      ? {
          "content-type": "application/json",
          token: token,
        }
      : {
          "content-type": "application/json",
        },
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status === true)
        return {
          data: data.data,
          status: data.status,
        };
      else {
        checkForExpiredToken(data.message);
        return { status: false, message: data.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong from login api",
        error,
      };
    });
};
export const groupBuyMemberPaymentAPI = async (id, memberId = null) => {
  return await fetch(GROUP_BUY_MEMBER_PAYMENT_DETAILS + id + memberId, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      token: store.getState().app.token,
    },
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status === true)
        return {
          data: data.data,
          message: data.message,
          status: data.status,
        };
      else {
        checkForExpiredToken(data.message);
        return { status: false, message: data.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong from login api",
        error,
      };
    });
};
export const saveGroupBuyMemberPaymentAPI = async (id, memberId, payload) => {
  return await fetch(GROUP_BUY_MEMBER_SAVE_PAYMENT_DETAILS + id + memberId, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      token: store.getState().app.token,
    },
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status === true)
        return {
          data: data.data,
          message: data.message,
          status: data.status,
        };
      else {
        checkForExpiredToken(data.message);
        return { status: false, message: data.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong from login api",
        error,
      };
    });
};
export const joinGroupBuyByAdminAPI = async (id, payload) => {
  return await fetch(GROUP_BUY_JOIN_BY_ADMIN + id, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      token: store.getState().app.token,
    },
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status === true)
        return {
          data: data.data,
          message: data.message,
          status: data.status,
        };
      else {
        checkForExpiredToken(data.message);
        return { status: false, message: data.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong from login api",
        error,
      };
    });
};
export const editGroupBuyMemberPaymentAPI = async (id, payload) => {
  return await fetch(GROUP_BUY_MEMBER_EDIT_PAYMENT_DETAILS + id, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      token: store.getState().app.token,
    },
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status === true)
        return {
          data: data.data,
          message: data.message,
          status: data.status,
        };
      else {
        checkForExpiredToken(data.message);
        return { status: false, message: data.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong from login api",
        error,
      };
    });
};
