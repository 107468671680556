import React from "react";
import FeatherIcon from "feather-icons-react";

const FormInputTextComponent = ({
  label,
  type = "text",
  name,
  id,
  value,
  placeholder,
  classnames,
  icon,
  error,
  onChange,
  handleInputIconClick,
  inputIcon = null,
  isRequired,
}) => {
  const handleChange = (e) => {
    if (onChange) onChange(name, e.target.value);
  };
  const onInputIconClick = () => {
    console.log(value);

    if (handleInputIconClick) handleInputIconClick(value);
  };

  return (
    <div className={`form-input-container ${error ? "error" : ""}`}>
      {label && (
        <p className="no-margin form-input-label">
          {label} <span className="color-red">{isRequired ? "*" : ""}</span>
        </p>
      )}

      <input
        className={
          classnames ||
          (inputIcon ? " search-input search-input-with-icon" : "search-input")
        }
        type={type}
        name={name}
        id={id}
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
      />
      {inputIcon && !error && (
        <span
          className="text-input-icon add-button add"
          onClick={onInputIconClick}
        >
          <FeatherIcon icon={inputIcon} width={15} />
        </span>
      )}
      {error && <span className="form-input-error"> {error} </span>}

      {icon && <span className="form-input-icon"> {icon} </span>}
    </div>
  );
};

export default FormInputTextComponent;
