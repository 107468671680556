import { API } from "../../constants/api.constants";
import { store } from "../../store";
import { checkForExpiredToken } from "../post/post.service";

const { ADMIN_INFO } = API;

export const getAdminInfoAPI = async () => {
  return await fetch(ADMIN_INFO, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      token: store.getState().app?.token,
    },
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status === true)
        return {
          data: data.data,
          status: data.status,
        };
      else {
        checkForExpiredToken(data.message);
        return { status: false, message: data.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong from login api",
        error,
      };
    });
};
