import ViewProductsComponent from "../../components/app-products/view-product.component";
import PageWrapperComponent from "../../components/page-wrapper/page-wrapper.component";

const ViewPostComponent = () => {
  return (
    <PageWrapperComponent>
      <ViewProductsComponent />
    </PageWrapperComponent>
  );
};

export default ViewPostComponent;
