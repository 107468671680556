export const getCategoryForm = (defaultValues) => {
  return [
    {
      type: "TEXT",
      label: "Category Name",
      id: "name",
      name: "name",
      value: defaultValues?.name || "",
      validations: [
        {
          type: "REQUIRED",
          message: "Name is required",
        },
      ],
    },
    {
      type: "FILE_UPLOAD",
      label: "Category Image",
      id: "image",
      name: "image",
      value: defaultValues?.image || "",
      placeholder: "Please select category image",
      validations: [],
    },
    {
      type: "SELECT",
      label: "Status",
      id: "status",
      name: "status",
      value: defaultValues?.status || 1,
      validations: [
        {
          type: "REQUIRED",
          message: "Name is required",
        },
      ],
      options: [
        {
          name: "Enabled",
          value: 1,
        },
        {
          name: "Disabled",
          value: 2,
        },
      ],
    },
  ];
};
