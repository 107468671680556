import FeatherIcon from "feather-icons-react";
import React from "react";
import { Sheet } from "react-modal-sheet";

const BottomSheetComponent = ({
  open,
  toggleBottomSheet,
  title,
  content,
  height = 1,
}) => {
  return (
    <Sheet
      disableDrag={false}
      snapPoints={[800, 600, 400]}
      initialSnap={height}
      isOpen={open}
      onClose={() => toggleBottomSheet(false)}
      // snapPoints={[height]}
    >
      <Sheet.Container style={{ backgroundColor: "#f5f5f9" }}>
        <Sheet.Header>
          <p
            style={{ paddingTop: 13 }}
            className="no-padding no-margin mt-30 t-a-c "
          >
            {title}
            <span style={{ float: "right" }}>
              <button
                className="cancel-button"
                onClick={() => toggleBottomSheet(false)}
              >
                <FeatherIcon icon={"x"} width={30} />
              </button>
            </span>
          </p>
        </Sheet.Header>

        <Sheet.Content>{content}</Sheet.Content>
      </Sheet.Container>

      <Sheet.Backdrop onClick={() => toggleBottomSheet(false)} />
    </Sheet>
  );
};

export default BottomSheetComponent;
