import React from "react";
import "./floating-button.css";

const FloatingButtonComponent = ({ icon, onClick, position }) => {
  const styles = {
    bottom: position?.bottom || "20px",
    right: position?.right || "20px",
    left: position?.left,
    top: position?.top,
  };

  return (
    <button className="floating-button" style={styles} onClick={onClick}>
      {icon}
    </button>
  );
};

export default FloatingButtonComponent;
