import React, { useEffect, useState } from "react";

import PageWrapperComponent from "../../components/page-wrapper/page-wrapper.component";
import AccountHeaderComponent from "../../components/account-header/account-header.component";
import { FaPlus } from "react-icons/fa";
import FloatingButtonComponent from "../../components/floating-icon/floating-icon.component";
import { useNavigate } from "react-router-dom";
import EmptyBoxComponent from "../../components/empty-box/empty-box";
import { deleteDataApi, getDataApi } from "./group-by.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBoxArchive,
  faEdit,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import LoaderComponent from "../../components/loader/loader";
// import
const GroupByComponent = () => {
  const navigate = useNavigate();
  const [all, setAll] = useState({ loading: true });
  const [tableData, setTableData] = useState([]);

  const gotoScreen = (url) => {
    navigate(url);
  };
  let params = {
    orderBy: "created_at",
    sortBy: "desc",
    skip: 0,
    limit: 20,
  };
  const onload = () => {
    getData(params);
  };

  const getData = async (params) => {
    const data = await getDataApi();
    setAll({ ...all, loading: false });
    if (data.status) {
      setTableData(data.data);
    }
  };
  const refreshData = () => {
    getData({
      orderBy: "created_at",
      sortBy: "desc",
      skip: 0,
      limit: 10,
    });
  };
  useEffect(() => {
    onload();
  }, []);

  const handleEdit = (group) => {
    navigate("/group-buy/edit", { state: group });
  };

  const handleProductClick = (group) => {
    navigate(`/group-buy/products/${group?.unique_id} `, { state: group });

    // group-buy/products/
    console.log(group);
  };
  const handleDeleteClick = (group) => {
    console.log(group);

    Swal.fire({
      title: "Delete Group!",
      text: `Are you sure you want to delete the ${group.name} group?`,
      icon: "info",
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
      showCancelButton: true,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        handleDeleteSubmit(group.unique_id);
      } else if (result.isDismissed) {
        console.log("Dismissed ");
      }
    });
  };
  const handleDeleteSubmit = async (id) => {
    const respose = await deleteDataApi(id);
    onload();
  };

  return (
    <PageWrapperComponent>
      <div className="page-container ">
        <AccountHeaderComponent name="Manage Group Buy" backRoute="/account" />
        <div className="account-content">
          {!all.loading && tableData.length === 0 && (
            <div className="container t-a-c">
              <EmptyBoxComponent />
            </div>
          )}

          {all.loading && (
            <div className="t-a-c">
              <LoaderComponent />
            </div>
          )}

          <div>
            <div>
              {tableData.map((item, i) => (
                <>
                  <div
                    key={i}
                    style={{
                      margin: "10px",
                      border: "1px solid #ccc",
                      borderRadius: "10px",
                      padding: "15px",
                      display: "flex",
                      alignItems: "center",
                      boxSizing: "border-box",
                      backgroundColor: "#fff",
                    }}
                  >
                    <img
                      src={item.image}
                      style={{
                        width: "10%",
                        borderRadius: "10px 10px 0 0",
                      }}
                    />
                    <span
                      style={{
                        marginLeft: "15px",
                        flexGrow: 1,
                        textAlign: "left",
                        fontWeight: "normal", // Set to normal here
                      }}
                    >
                      {item.name}
                    </span>

                    <div style={{ marginLeft: "15px", textAlign: "right" }}>
                      <FontAwesomeIcon
                        icon={faTrashCan}
                        style={{ cursor: "pointer", color: "#dc3545" }}
                        onClick={() => handleDeleteClick(item)}
                      />
                    </div>
                    <div style={{ marginLeft: "15px", textAlign: "right" }}>
                      <FontAwesomeIcon
                        icon={faBoxArchive}
                        style={{ cursor: "pointer", color: "#4ebeff" }}
                        onClick={() => handleProductClick(item)}
                      />
                    </div>
                    <div style={{ marginLeft: "15px", textAlign: "right" }}>
                      <FontAwesomeIcon
                        icon={faEdit}
                        style={{ cursor: "pointer", color: "#7c77fb" }}
                        onClick={() => handleEdit(item)}
                      />
                    </div>
                  </div>
                  <div> </div>
                </>
              ))}
            </div>
          </div>

          <FloatingButtonComponent
            icon={<FaPlus />}
            onClick={() => gotoScreen("/group-buy/add")}
            position={{ bottom: "30px", right: "30px" }}
          />
        </div>
      </div>
    </PageWrapperComponent>
  );
};

export default GroupByComponent;
