import React from "react";
import { FaArrowRight, FaDoorOpen } from "react-icons/fa";
import ButtonComponent from "../../components/button/button.component";
const Customer = ({ user, handleLogout }) => {
  return (
    <>
      <div className="container card t-a-c" style={{ marginTop: 10 }}>
        <p className="account-name no-padding no-margin"> {user?.name} </p>
        <p className="account-mobile no-padding no-margin"> {user?.phone} </p>
        <p className="account-role no-padding no-margin">{user?.address}</p>
      </div>

      <div className="container mt-10">
        <ButtonComponent text={"Logout"} onClick={handleLogout} />
      </div>
    </>
  );
};

export default Customer;
