import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import PageWrapperComponent from "../../components/page-wrapper/page-wrapper.component";
import AccountHeaderComponent from "../../components/account-header/account-header.component";
import { useNavigate, useParams } from "react-router-dom";
import {
  editGroupBuyMemberPaymentAPI,
  getGroupBuyDataAPI,
  getGroupBuyMembersDownloadAPI,
  joinGroupBuyAPI,
  leaveGroupBuyAPI,
  memberRegistrationAPI,
  memberRegistrationVerifyAPI,
} from "./group-buy.service";
import EmptyBoxComponent from "../../components/empty-box/empty-box";
import LoaderComponent from "../../components/loader/loader";

import ButtonComponent from "../../components/button/button.component";
import BottomSheetComponent from "../../components/bottom-sheet/bottom-sheet";
import { useDispatch, useSelector } from "react-redux";
import { setUserTokenAction } from "../../reducers/app.reducer";
import LinkShareButton from "../../components/post-share/link-share-button";
import { APP_URL } from "../../constants/api.constants";
import { FaCheckCircle, FaDownload, FaEdit, FaUpload } from "react-icons/fa";
import CustomerRegistration from "./customer-registration";
import FeatherIcon from "feather-icons-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import FormBuilderComponent from "../../components/form-builder/form-builder";
import { getPaymentFormInputs } from "./payment-form.constant";
import { API } from "../../constants/api.constants";

const GroupBuyScreen = () => {
  const { user } = useSelector((state) => state.app);
  const { token } = useSelector((state) => state.app);
  const { GROUP_BUY_MEMBERS_DOWNLOAD } = API;

  const { uniqueId } = useParams();
  const [all, setAll] = useState({
    groupBuy: null,
    loading: true,
    showJoinModal: false,
    memberDetails: null,
    joinError: "",
    modalLoading: false,
    totalQty: 0,
    totalAmount: 0,
  });

  const [editMemberDetails, setEditMemberDetails] = useState({
    openEditModel: false,
    deatilsId: null,

    details: {},
  });
  const {
    groupBuy,
    loading,
    showJoinModal,
    memberDetails,
    modalLoading,
    totalQty,
    totalAmount,
  } = all;
  const [resendTimer, setResendTimer] = useState(60);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onLoad = async () => {
    const res = await getGroupBuyDataAPI(uniqueId);

    if (res.status) {
      let totalQty = 0;
      let totalAmount = 0;
      res.data.total_members.map((member) => {
        totalQty = totalQty + parseInt(member.payment_details.qty);
        totalAmount += parseInt(member.payment_details.amount || 0);
      });
      setAll({ loading: false, groupBuy: res.data, totalQty, totalAmount });
    } else {
      setAll({ loading: false });
    }
  };

  const toggleBottomSheet = (value) => {
    setAll({ ...all, showJoinModal: value });
    setEditMemberDetails({ openEditModel: false, details: {} });
  };

  const handleMemberJoin = async (values) => {
    setAll({ ...all, modalLoading: true });
    const res = await memberRegistrationAPI(values);

    if (res.status) {
      setAll({ ...all, memberDetails: values, modalLoading: false });
      setResendTimer(60);
    }
  };

  const handleResendOTP = async () => {
    const res = await memberRegistrationAPI(memberDetails);
    if (res.status) {
      setResendTimer(60);
    }
  };

  const handleMemberVerify = async (values) => {
    setAll({ ...all, modalLoading: true });
    const res = await memberRegistrationVerifyAPI({
      ...values,
      phone: memberDetails.phone,
    });

    if (res.status) {
      dispatch(
        setUserTokenAction({
          ...res.data,
          user: { ...res.data.user, type: "customer" },
        })
      );
      handleJoin();
    }
  };

  const handleJoin = async () => {
    const joinRes = await joinGroupBuyAPI(uniqueId);

    if (joinRes.status) {
      onLoad();
    } else alert(joinRes.message);

    setAll({ ...all, showJoinModal: false, modalLoading: false });
  };

  const handleLeave = () => {
    Swal.fire({
      title: "Leave Group Buy !",
      text: "Are you sure to leave the group buy?",
      icon: "info",
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
      showCancelButton: true,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        handleLeaveConfirm();
      } else if (result.isDismissed) {
        console.log("Dismissed ");
      }
    });
  };

  const handleLeaveConfirm = async () => {
    const joinRes = await leaveGroupBuyAPI(uniqueId);
    if (joinRes.status) {
      onLoad();
    }
  };

  useEffect(() => {
    onLoad();
  }, []);

  useEffect(() => {
    let timer;
    if (memberDetails && resendTimer > 0) {
      timer = setInterval(() => {
        setResendTimer((prevSeconds) => prevSeconds - 1);
      }, 1000);
    }

    // Cleanup the interval when the component unmounts or timer stops
    return () => clearInterval(timer);
  }, [memberDetails, resendTimer]);

  if (loading)
    return (
      <div className="t-a-c" style={{ paddingTop: 125 }}>
        <LoaderComponent
          path={require("./../../assets/icons/loading-gif.gif")}
        />
      </div>
    );

  let emptyPost = false;
  if (!groupBuy || (groupBuy && groupBuy.is_deleted === 2)) emptyPost = true;

  // logged in customer or admin
  let loggedInAdminOrUser = false;
  let currentCustomerJoinedId = null;
  let loggedInAdmin = false;
  if (
    (user && user.role_name === "Admin") ||
    (user && user.phone === groupBuy.group_buy_admin.phone)
  )
    loggedInAdmin = true;

  if (user && user.role_name === "Admin" && user.customer) {
    loggedInAdminOrUser = true;
    currentCustomerJoinedId = groupBuy.total_members.find(
      (member) => member.customer_id === user.customer.id
    );
  }

  if (user && user.type === "customer") {
    loggedInAdminOrUser = true;
    currentCustomerJoinedId = groupBuy.total_members.find(
      (member) => member.customer_id === user.id
    );
  }

  const handleJoinClick = () => {
    if (loggedInAdminOrUser) {
      Swal.fire({
        title: "Join Group Buy !",
        text: "Are you sure to join the group buy?",
        icon: "info",
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        showCancelButton: true,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          handleJoin();
        } else if (result.isDismissed) {
          console.log("Dismissed ");
        }
      });
    } else navigate(`/group-buy/${uniqueId}/join`);
  };

  const handleEditMemberDetails = (values) => {
    navigate(`/group-buy/${uniqueId}/payment`, {
      state: { values, loggedInAdmin },
    });
  };

  const handleDetailsEditSubmit = async (values) => {
    console.log(values);

    let payload = { payment_details: values };
    const response = await editGroupBuyMemberPaymentAPI(
      editMemberDetails?.deatilsId,
      payload
    );
    if (response.status)
      setEditMemberDetails({
        openEditModel: false,
        deatilsId: null,
        details: {},
      });
    onLoad();
  };

  const handleDownload = async () => {
    console.log(uniqueId);
    window.open(`${GROUP_BUY_MEMBERS_DOWNLOAD}${uniqueId}?token=${token}`);
  };

  return (
    <PageWrapperComponent>
      <AccountHeaderComponent name="Home" backRoute="/" />
      <div>
        {emptyPost && (
          <div className="container t-a-c" style={{ marginTop: 70 }}>
            <EmptyBoxComponent message="The group buy you're looking for not found !" />
          </div>
        )}
      </div>
      <div className="gb-s-container">
        <div className="gb-s-header">
          <div className="flex">
            <div>
              <img
                src={
                  groupBuy.image
                    ? groupBuy.image
                    : require("./../../assets/icons/no-photos.png")
                }
              />
            </div>
            <div>
              <p className="gb-s-title"> {groupBuy.name}</p>
              <p className="gb-s-members-count">
                Memebers Count : {groupBuy?.total_members?.length}
              </p>
              <p className="gb-s-admin">
                Admin : {groupBuy?.group_buy_admin?.name}
              </p>
              <p className="gb-s-admin red-color">GB QTY : {totalQty}</p>
              <p style={{ paddingBottom: 10 }} className="gb-s-admin red-color">
                GB Total : {totalAmount}
              </p>
            </div>
          </div>
          <div className="gb-s-desc">{groupBuy.description}</div>

          <div style={{ paddingBottom: 12 }}>
            <LinkShareButton url={APP_URL + "group-buy/" + uniqueId} />
            {loggedInAdmin && (
              <span
                className="contact-button contact-mode"
                style={{ backgroundColor: "#7c77fb" }}
              >
                <a onClick={() => handleDownload()}>
                  <FaDownload style={{ width: 20, height: 20 }} color="white" />
                </a>
              </span>
            )}
          </div>
        </div>

        <div>
          {currentCustomerJoinedId && (
            <ButtonComponent
              className="danger-button"
              text={"Leave"}
              onClick={() => handleLeave()}
            />
          )}

          {!currentCustomerJoinedId && (
            <ButtonComponent text={"Join"} onClick={() => handleJoinClick()} />
          )}
        </div>

        <div>
          <p className="mt-10 gb-s-members-title">
            {" "}
            Members{" "}
            {user?.role_name == "Admin" && (
              <span
                className="add-button   add
                "
                style={{ float: "right" }}
              >
                <FontAwesomeIcon
                  icon={faPlus}
                  onClick={() => navigate(`/group-buy/${uniqueId}/join`)}
                />
              </span>
            )}
          </p>
          {groupBuy.total_members.length === 0 && (
            <div>
              <EmptyBoxComponent message="No members !" />
            </div>
          )}

          {groupBuy.total_members.map((member, i) => (
            <div className="gb-member-card">
              <div
                className="flex"
                style={{ justifyContent: "space-between", paddingTop: 10 }}
              >
                <div style={{ width: "80%" }}>
                  <p className="no-margin">
                    {groupBuy.total_members.length - i}. {member.customer.name}
                  </p>

                  {loggedInAdmin && (
                    <>
                      <p className="gb-s-member-address">
                        {member?.customer?.address},{" "}
                        {member?.customer?.location}, {member?.customer?.state}{" "}
                        - {member?.customer?.pincode}.{" "}
                      </p>
                      <p className="gb-s-member-address">
                        {"Mobile : "}
                        {member?.customer?.phone}{" "}
                      </p>
                      {member.payment_details &&
                        member.payment_details.screenshot && (
                          <>
                            <p className="gb-s-member-address">
                              {"QTY Needed : "}
                              {member?.payment_details?.qty || "1"}
                            </p>

                            <p className="gb-s-member-address">
                              {"Amount Paid : "}
                              {member?.payment_details?.amount || ""}
                            </p>

                            {member?.payment_details?.transaction_id && (
                              <p className="gb-s-member-address">
                                {"Trans ID : "}
                                {member?.payment_details?.transaction_id}{" "}
                              </p>
                            )}

                            <div style={{ paddingLeft: 30, textAlign: "left" }}>
                              <img
                                onClick={() =>
                                  window.open(
                                    member.payment_details.screenshot,
                                    "_blank"
                                  )
                                }
                                className="payment-screenshot"
                                src={member.payment_details.screenshot}
                              />
                            </div>
                          </>
                        )}
                    </>
                  )}
                  {!loggedInAdmin && (
                    <p className="gb-s-member-address">
                      {member?.customer?.location}
                    </p>
                  )}
                </div>
                {currentCustomerJoinedId &&
                  member.id === currentCustomerJoinedId.id && (
                    <p
                      className="no-margin"
                      onClick={() => navigate(`/group-buy/${uniqueId}/payment`)}
                    >
                      <FaUpload className="app-color capture-payment" />
                    </p>
                  )}

                {member.payment_details_status && (
                  <FaCheckCircle className="green-color capture-payment" />
                )}
                {loggedInAdmin && (
                  <FaEdit
                    className="app-color capture-payment"
                    onClick={() => handleEditMemberDetails(member)}
                  />
                )}
              </div>
            </div>
          ))}
        </div>

        <BottomSheetComponent
          height={memberDetails ? 1 : 0}
          open={showJoinModal}
          title={
            user && user.type === "customer"
              ? "Join Group Buy !"
              : "Group Member Details"
          }
          toggleBottomSheet={toggleBottomSheet}
          content={
            <CustomerRegistration
              modalLoading={modalLoading}
              user={user}
              resendTimer={resendTimer}
              memberDetails={memberDetails}
              loggedInAdminOrUser={loggedInAdminOrUser}
              handleJoin={handleJoin}
              handleMemberVerify={handleMemberVerify}
              handleMemberJoin={handleMemberJoin}
              handleResendOTP={handleResendOTP}
            />
          }
        />
        <BottomSheetComponent
          height={1}
          open={editMemberDetails?.openEditModel}
          title={"Edit Details"}
          toggleBottomSheet={toggleBottomSheet}
          content={
            <FormBuilderComponent
              inputs={getPaymentFormInputs(editMemberDetails?.details)}
              onSubmit={handleDetailsEditSubmit}
            />
          }
        />
      </div>
    </PageWrapperComponent>
  );
};

export default GroupBuyScreen;
