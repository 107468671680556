import React, { useEffect, useState } from "react";

import PageWrapperComponent from "../../components/page-wrapper/page-wrapper.component";
import AccountHeaderComponent from "../../components/account-header/account-header.component";

import { editCategoryApi, imageUploadAPI } from "./category.service";
import { BUCKET_URL } from "../../constants/api.constants";
import { useLocation, useNavigate } from "react-router-dom";
import CategoryForm from "./category-form";
import FormBuilderComponent from "../../components/form-builder/form-builder";
import { getCategoryForm } from "./form.constant";

const EditCategoryComponent = () => {
  const { state } = useLocation();

  const navigate = useNavigate();

  const gotoScreen = (url) => {
    navigate(url);
  };
  const [all, setAll] = useState({
    id: "",
    name: "",
    nameError: "",
    status: "",
    image: "",
  });

  const handleSubmit = async (values) => {
    console.log({ values });

    const resp = await editCategoryApi(all?.id, values);
    console.log(resp.status);
    if (resp.status) {
      gotoScreen("/category");
    }
  };
  useEffect(() => {
    setAll(state);
  }, [state]);

  return (
    <PageWrapperComponent>
      <div className="page-container">
        <AccountHeaderComponent name="Edit Category" backRoute="/category" />
        <div style={{ paddingTop: 70, paddingLeft: 12, paddingRight: 12 }}>
          <FormBuilderComponent
            inputs={getCategoryForm(state)}
            onSubmit={handleSubmit}
          />
          {/* <CategoryForm
            all={all}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
          /> */}
        </div>
      </div>
    </PageWrapperComponent>
  );
};

export default EditCategoryComponent;
