import React from "react";

const LoaderComponent = ({ path }) => {
  return (
    <div>
      <img
        className="loader-image"
        src={path ? path : require("./../../assets/icons/loading-gif.gif")}
      />
      <p className="no-margin"> Loading </p>
    </div>
  );
};

export default LoaderComponent;
