import React, { useEffect, useState } from "react";
import PageWrapperComponent from "../../../components/page-wrapper/page-wrapper.component";
import AccountHeaderComponent from "../../../components/account-header/account-header.component";
import {
  groupBuyMemberPaymentAPI,
  leaveGroupBuyAPI,
  saveGroupBuyMemberPaymentAPI,
} from "../group-buy.service";
import LoaderComponent from "../../../components/loader/loader";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import FormBuilderComponent from "../../../components/form-builder/form-builder";
import { getPaymentFormInputs } from "../payment-form.constant";
import ButtonComponent from "../../../components/button/button.component";
import Swal from "sweetalert2";

const GroupBuyPaymentComponent = () => {
  const [all, setAll] = useState({
    loading: true,
    data: {},
  });
  const { uniqueId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const { loading, data } = all;

  const memberId = location?.state?.values?.id || null;
  const loggedInAdmin = location?.state?.loggedInAdmin || false;

  const onLoad = async () => {
    console.log({ location });

    const response = await groupBuyMemberPaymentAPI(
      uniqueId,
      memberId ? "/" + memberId : ""
    );
    // console.log(response);

    if (response.status) {
      setAll({ loading: false, data: response.data });
    } else {
      setAll({ loading: false });
    }
  };

  const handleSubmit = async (values) => {
    // console.log(values);

    let payload = {
      payment_details: values,
    };
    const response = await saveGroupBuyMemberPaymentAPI(
      uniqueId,
      memberId ? "/" + memberId : "",
      payload
    );

    if (response.status) {
      setAll({ ...all, openModal: false });
      navigate(`/group-buy/${uniqueId}`);
    } else alert(response.message);
  };

  const handleRemoveFromGB = async () => {
    Swal.fire({
      title: "Remove from Group Buy !",
      text: "Are you sure to remove this member from group buy?",
      icon: "info",
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
      showCancelButton: true,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        handleLeaveConfirm();
      } else if (result.isDismissed) {
        console.log("Dismissed ");
      }
    });
  };

  const handleLeaveConfirm = async () => {
    const response = await leaveGroupBuyAPI(
      uniqueId,
      memberId ? "/" + memberId : ""
    );

    console.log({ response });

    navigate(`/group-buy/${uniqueId}`);
  };

  useEffect(() => {
    onLoad();
  }, []);
  // console.log(data);

  return (
    <PageWrapperComponent>
      <AccountHeaderComponent
        name={`Payment ${data?.name || ""}`}
        backRoute={"/group-buy/" + uniqueId}
      />
      <div style={{ paddingTop: 70 }}>
        {loading && (
          <div className="t-a-c">
            <LoaderComponent />
          </div>
        )}

        {!loading && data && (
          <div className="payment-container">
            <FormBuilderComponent
              inputs={getPaymentFormInputs(data)}
              onSubmit={handleSubmit}
            />

            {loggedInAdmin && (
              <div>
                <ButtonComponent
                  text={"Remove from Group Buy"}
                  className="danger-button"
                  onClick={handleRemoveFromGB}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </PageWrapperComponent>
  );
};

export default GroupBuyPaymentComponent;
