import { customerAutoCompleteApi } from "../customers/customers.service";

export const getGroupByForm = (defaultValues) => {
  return [
    {
      type: "TEXT_AUTOCOMPLETE",
      label: "Group Admin",
      id: "group_buy_admin_id",
      name: "group_buy_admin_id",
      value: defaultValues?.group_buy_admin_id || "",
      validations: [
        {
          type: "REQUIRED",
          message: "Customer is required",
        },
      ],
      fetchOptions: customerAutoCompleteApi,
      formatOption: (option) => `${option.name} - ${option.phone}`,
    },
    {
      type: "TEXT",
      label: "Name",
      id: "name",
      name: "name",
      value: defaultValues?.name || "",
      placeholder: "Please Enter the  name ",
      validations: [
        {
          type: "REQUIRED",
          message: "name is required",
        },
      ],
    },
    {
      type: "TEXT_AREA",
      label: "Description",
      id: "description",
      name: "description",
      value: defaultValues?.description || "",
      placeholder: "Please Enter the  name ",
      validations: [
        {
          type: "REQUIRED",
          message: "Description is required",
        },
      ],
    },
    {
      type: "CALENDER",
      label: "Expected landing date",
      id: "expected_landing_date",
      name: "expected_landing_date",
      value: defaultValues?.expected_landing_date || "",
      placeholder: "Select Expected landle date  ",
      validations: [],
    },
    {
      type: "CALENDER",
      label: "Last date to enroll",
      id: "last_day_to_enroll",
      name: "last_day_to_enroll",
      value: defaultValues?.last_day_to_enroll || "",
      placeholder: "Please Enter the  name ",
      validations: [],
    },
    {
      type: "FILE_UPLOAD",
      label: "Image",
      id: "image",
      name: "image",
      value: defaultValues?.image || "",
      placeholder: "Upload the image file",
      validations: [],
    },
    {
      type: "SELECT",
      label: "Status",
      id: "status",
      name: "status",
      value: defaultValues?.status || 1,
      validations: [
        {
          type: "REQUIRED",
          message: "Name is required",
        },
      ],
      options: [
        {
          name: "Enabled",
          value: 1,
        },
        {
          name: "Disabled",
          value: 2,
        },
      ],
    },
  ];
};
export const getGroupBuyProductsForm = (defaultValues) => {
  // console.log(defaultValues);

  return [
    {
      type: "TEXT",
      label: "Name",
      id: "name",
      name: "name",
      value: defaultValues?.name || "",
      placeholder: "Please Enter the  name ",
      validations: [
        {
          type: "REQUIRED",
          message: "name is required",
        },
      ],
    },
    {
      type: "TEXT",
      label: "Price",
      id: "price",
      name: "price",
      value: defaultValues?.price || "",
      placeholder: "Please Enter the  price ",
      validations: [
        {
          type: "REQUIRED",
          message: "price is required",
        },
      ],
    },
  ];
};
