import React, { useEffect, useState } from "react";

import PageWrapperComponent from "../../components/page-wrapper/page-wrapper.component";
import AccountHeaderComponent from "../../components/account-header/account-header.component";

import { useNavigate } from "react-router-dom";
import { createCustomerAPI } from "./customers.service";
import CustomerForm from "./customers-form";
import FormBuilderComponent from "../../components/form-builder/form-builder";
import { getCustomerForm } from "./form.components";

const AddCustomerComponent = ({
  isShowheader = true,
  customer = {},
  setClose,
  setAll,
}) => {
  const navigate = useNavigate();

  const gotoScreen = (url) => {
    navigate(url);
  };
  console.log(setAll);

  const handleSubmit = async (values) => {
    const resp = await createCustomerAPI(values);
    // console.log(resp.data);

    if (resp.status) {
      // handleChange()
      // setAll((prevState) => ({
      //   ...prevState,
      //   ["category_id"]: resp.data?.id,
      // }));

      let customerData = {
        name: resp.data?.name,
        value: resp.data?.id,
      };

      // handleChange(customerData, resp.data?.id);

      if (!isShowheader) {
        setAll((prevState) => ({
          ...prevState,
          customer_id: resp.data?.id,
        }));
        setClose(false);
      } else {
        gotoScreen("/customers");
      }
    }
  };

  // useEffect(() => {
  //   if (!isShowheader) {
  //     setAll((prevState) => ({
  //       ...prevState,
  //       name: nameValue,
  //     }));
  //   }
  // }, [isShowheader, nameValue]);

  return (
    <PageWrapperComponent>
      <div className="page-container">
        {isShowheader && (
          <AccountHeaderComponent name="Add Customers" backRoute="/customers" />
        )}

        <div style={{ paddingTop: 70, paddingLeft: 12, paddingRight: 12 }}>
          <FormBuilderComponent
            onSubmit={handleSubmit}
            inputs={getCustomerForm(customer)}
          />
        </div>
      </div>
    </PageWrapperComponent>
  );
};

export default AddCustomerComponent;
