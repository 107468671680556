export const getPaymentFormInputs = (defaultValues) => {
  return [
    {
      inputType: "number",
      type: "TEXT",
      label: "QTY needed",
      id: "qty",
      name: "qty",
      value: defaultValues?.qty || "",
      validations: [
        {
          type: "REQUIRED",
          message: "QTY is required",
        },
      ],
    },

    {
      inputType: "number",
      type: "TEXT",
      label: "Amount Paid",
      id: "amount",
      name: "amount",
      value: defaultValues?.amount || "",
      validations: [
        {
          type: "REQUIRED",
          message: "Amount is required",
        },
      ],
    },

    {
      type: "FILE_UPLOAD",
      label: "Screenshot",
      id: "screenshot",
      name: "screenshot",
      value: defaultValues?.screenshot || "",
      validations: [
        {
          type: "REQUIRED",
          message: "Screenshot is required",
        },
      ],
    },

    {
      type: "TEXT",
      label: "Transaction ID",
      id: "transaction_id",
      name: "transaction_id",
      value: defaultValues?.transaction_id || "",
      validations: [],
    },
  ];
};
