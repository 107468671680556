import { API } from "../../constants/api.constants";
import { resetUserTokenAction } from "../../reducers/app.reducer";
import { store } from "../../store";

const {
  CREATE_CATEGORY,
  UPLOAD_IMAGE,
  CATEGORY_ALL,
  EDIT_CATEGORY,
  DELETE_CATEGORY,
  CATEGORY_LIST_ALL,
} = API;
export const getDataApi = async ({ orderBy, sortBy, skip, limit, filters }) => {
  return await fetch(
    `${CATEGORY_ALL}?sort_by=${sortBy}&order_by=${orderBy}&skip=${skip}&limit=${limit}`,
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
    }
  )
    .then((res) => res.json())
    .then((response) => {
      console.log(response.data);
      if (response.status)
        return {
          status: true,
          data: response.data.list,
          totalCount: response.data.total_count,
        };
      else {
        return { status: false, message: response.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong, please try again later.",
        error,
      };
    });
};
export const createCategoryAPI = async (payload) => {
  return await fetch(CREATE_CATEGORY, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      token: store.getState().app?.token,
    },
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status === true)
        return {
          data: data.data,
          status: data.status,
        };
      else {
        checkForExpiredToken(data.message);
        return { status: false, message: data.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong from login api",
        error,
      };
    });
};
export const editCategoryApi = async (id, payload) => {
  return await fetch(EDIT_CATEGORY + id, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      token: store.getState().app?.token,
    },
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status === true)
        return {
          data: data.data,
          status: data.status,
        };
      else {
        checkForExpiredToken(data.message);
        return { status: false, message: data.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong from login api",
        error,
      };
    });
};
export const checkForExpiredToken = (message) => {
  if (
    message === "Token expired" ||
    message === "Invalid token" ||
    message === "Token refresh"
  ) {
    store.dispatch(resetUserTokenAction());
  }
};

export const imageUploadAPI = async (file) => {
  const formData = new FormData();
  formData.append("file", file);
  return await fetch(UPLOAD_IMAGE, {
    method: "POST",
    headers: {
      token: store.getState().app.token,
    },
    body: formData,
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status)
        return { status: true, data: data.data, message: data.message };
      else {
        checkForExpiredToken(data.message);
        return { status: false, message: data.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong, please try again later.",
        error,
      };
    });
};
export const deleteDataApi = async (ids) => {
  return await fetch(DELETE_CATEGORY, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      token: store.getState().app.token,
    },
    body: JSON.stringify({ ids }),
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status)
        return {
          status: true,
          data: data.data.list,
          totalCount: data.data.total_count,
        };
      else {
        checkForExpiredToken(data.message);
        return { status: false, message: data.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong, please try again later.",
        error,
      };
    });
};
export const getAllDataApi = async () => {
  return await fetch(CATEGORY_LIST_ALL, {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((response) => {
      // console.log(response.data);
      if (response.status)
        return {
          status: true,
          data: response.data,
        };
      else {
        return { status: false, message: response.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong, please try again later.",
        error,
      };
    });
};
