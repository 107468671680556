import { createAction, createReducer } from "@reduxjs/toolkit";

// Action creators
export const setUserTokenAction = createAction("app/token");
export const resetUserTokenAction = createAction("app/resetUserTokenAction");
export const setUserToken = createAction("app/setUserToken");

// Reducer using builder callback notation
export const appReducer = createReducer(
  {
    token: null,
    user: null,
    tokenExpired: null,
  },
  (builder) => {
    builder
      .addCase(setUserTokenAction, (state, action) => {
        state.token = action.payload.token;
        state.user = action.payload.user;
      })
      .addCase(resetUserTokenAction, (state, action) => {
        state.token = action.payload;
        state.user = action.payload;
      })

      .addCase(setUserToken, (state, action) => {
        state.user = action.payload;
      });
  }
);
