import { customerAutoCompleteApi } from "../customers/customers.service";
import { APP } from "../../constants/app.constant";

const { STATES } = APP;
export const getCustomerForm = (defaultValues) => {
  return [
    {
      type: "TEXT",
      label: "Name",
      id: "name",
      name: "name",
      value: defaultValues?.name || "",
      placeholder: "Please Enter the  name ",
      validations: [
        {
          type: "REQUIRED",
          message: "name is required",
        },
      ],
    },
    {
      type: "TEXT",
      label: "Phone",
      id: "phone",
      name: "phone",
      value: defaultValues?.phone || "",
      placeholder: "Please Enter the  Phone ",
      validations: [
        {
          type: "REQUIRED",
          message: "Phone no  is required",
        },
      ],
    },
    {
      type: "TEXT_AREA",
      label: "Address",
      id: "address",
      name: "address",
      value: defaultValues?.address || "",
      placeholder: "Please Enter the  Address ",
      validations: [
        {
          type: "REQUIRED",
          message: "Address   is required",
        },
      ],
    },
    {
      type: "TEXT",
      label: "Location",
      id: "location",
      name: "location",
      value: defaultValues?.location || "",
      placeholder: "Please Enter the  location ",
      validations: [],
    },
    {
      type: "TEXT",
      label: "Pincode",
      id: "pincode",
      name: "pincode",
      value: defaultValues?.pincode || "",
      placeholder: "Please Enter the  pincode ",
      validations: [],
    },
    {
      type: "SELECT",
      label: "State",
      id: "state",
      name: "state",
      options: STATES,
      value: defaultValues?.state || "",
      placeholder: "Select the state",
      validations: [],
    },
  ];
};
