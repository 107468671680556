import React, { useState } from "react";
import FormInputTextComponent from "../form-inputs/form-input-text/form-input-text.component";
import FormInputSelectComponent from "../form-inputs/form-input-select/form-input-select.component";
import ButtonComponent from "../button/button.component";
import FormInputFileUploadComponent from "../form-inputs/form-file-upload/form-file-upload.component";
import { imageUploadAPI } from "../../screens/category/category.service";
import { BUCKET_URL } from "../../constants/api.constants";
import FormInputDateComponent from "../form-inputs/form-input-date/form-input-date.component";
import FormInputTextAutoCompeleteComponent from "../form-inputs/form-input-text-autocomplete/form-input-auto-complete.componet";
import FormInputTextAreaComponent from "../form-inputs/form-input-text/form-input-textarea.component";

const FormBuilderComponent = ({ inputs, onSubmit }) => {
  const [formInputs, setFormInputs] = useState(inputs);

  const handleChange = ({ i, name, value }) => {
    let copyInputs = [...formInputs];
    copyInputs[i].value = value;

    setFormInputs(copyInputs);
  };

  const validateForm = () => {
    let copyInputs = [...formInputs];
    for (let i = 0; i < copyInputs.length; i++) {
      for (let j = 0; j < copyInputs[i].validations.length; j++) {
        copyInputs[i].error = "";
        if (
          copyInputs[i].validations[j].type === "REQUIRED" &&
          !copyInputs[i].value
        ) {
          copyInputs[i].error = copyInputs[i].validations[j].message;
        }
      }
    }
    setFormInputs(copyInputs);
    const errorFiels = copyInputs.filter((input) => input.error);
    let values = {};
    for (let i = 0; i < copyInputs.length; i++)
      values[copyInputs[i].name] = copyInputs[i].value;

    return { status: errorFiels.length > 0 ? true : false, errorFiels, values };
  };

  const handleSubmit = () => {
    const validationRes = validateForm();
    console.log(validationRes);

    if (validationRes.status) return;
    onSubmit(validationRes.values);
  };

  const checkForRequired = (validations) => {
    if (
      validations &&
      validations.find((validation) => validation.type === "REQUIRED")
    )
      return true;

    return false;
  };

  const handleFileUpload = async (name, value) => {
    const resp = await imageUploadAPI(value);
    console.log({ resp });
    if (resp.status) {
      let image = BUCKET_URL + "/app-source/images/" + resp.data;
      const copyInputs = formInputs.map((input) =>
        input.name === name ? { ...input, value: image } : input
      );

      setFormInputs(copyInputs);
    }
  };

  return (
    <div classname="form-builder-container">
      {formInputs.map((input, i) => (
        <>
          {input.type === "TEXT" && (
            <div className="mb-10">
              <FormInputTextComponent
                type={input?.inputType || "text"}
                label={input.label}
                id={input.id}
                name={input.name}
                value={input.value}
                error={input.error}
                isRequired={checkForRequired(input.validations)}
                onChange={(name, value) => handleChange({ i, name, value })}
              />
            </div>
          )}
          {input.type === "TEXT_AREA" && (
            <div className="mb-10">
              <FormInputTextAreaComponent
                label={input.label}
                id={input.id}
                name={input.name}
                value={input.value}
                error={input.error}
                isRequired={checkForRequired(input.validations)}
                onChange={(name, value) => handleChange({ i, name, value })}
              />
            </div>
          )}
          {input.type === "SELECT" && (
            <div className="mb-10">
              <FormInputSelectComponent
                label={input.label}
                id={input.id}
                name={input.name}
                value={input.value}
                isRequired={checkForRequired(input.validations)}
                options={input.options}
                error={input.error}
                onChange={(name, value) => handleChange({ i, name, value })}
              />
            </div>
          )}

          {input.type === "FILE_UPLOAD" && (
            <div className="mb-10">
              <FormInputFileUploadComponent
                name={input.name}
                label={input.label}
                error={input.error}
                placeholder={input.placeholder}
                value={input.value}
                isRequired={checkForRequired(input.validations)}
                onChange={handleFileUpload}
              />
            </div>
          )}
          {input.type === "CALENDER" && (
            <FormInputDateComponent
              label={input.label}
              id={input.id}
              name={input.name}
              value={input.value}
              error={input.error}
              isRequired={checkForRequired(input.validations)}
              onChange={(name, value) => handleChange({ i, name, value })}
            />
          )}
          {input.type === "TEXT_AUTOCOMPLETE" && (
            <FormInputTextAutoCompeleteComponent
              label={input.label}
              id={input.id}
              name={input.name}
              value={input.value}
              error={input.error}
              isRequired={checkForRequired(input.validations)}
              onChange={(name, value) =>
                handleChange({ i, name: input.name, value: name.value })
              }
              fetchOptions={input.fetchOptions}
              formatOption={input.formatOption}
              handleInputIconClick={input.handleInputIconClick}
              inputIcon={input.inputIcon}
            />
          )}
        </>
      ))}

      <ButtonComponent text={"Submit"} onClick={handleSubmit} />
    </div>
  );
};

export default FormBuilderComponent;
