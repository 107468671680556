import React from "react";

const EmptyBoxComponent = ({
  message = " No data found",
  image = "empty-box.png",
}) => {
  return (
    <div className="card">
      <img
        className="empty-image"
        src={require("./../../assets/icons/" + image)}
      />
      <p> {message} </p>
    </div>
  );
};

export default EmptyBoxComponent;
