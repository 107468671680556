import React, { useEffect, useState } from "react";

import PageWrapperComponent from "../../components/page-wrapper/page-wrapper.component";
import AccountHeaderComponent from "../../components/account-header/account-header.component";

import { useLocation, useNavigate } from "react-router-dom";
import FormBuilderComponent from "../../components/form-builder/form-builder";
import { getGroupByForm } from "./form.constant";
import { editApi } from "./group-by.service";

const EditGroupByComponent = () => {
  const { state } = useLocation();

  const navigate = useNavigate();

  const gotoScreen = (url) => {
    navigate(url);
  };
  const [all, setAll] = useState({
    id: "",
    name: "",
    nameError: "",
    status: "",
    image: "",
  });

  const handleSubmit = async (values) => {
    console.log({ values });

    const resp = await editApi(all?.unique_id, values);
    console.log(resp.status);
    if (resp.status) {
      gotoScreen("/group-buy");
    }
  };
  useEffect(() => {
    setAll(state);
  }, [state]);

  return (
    <PageWrapperComponent>
      <div className="page-container">
        <AccountHeaderComponent name="Edit Group Buy" backRoute="/group-buy" />
        <div style={{ paddingTop: 70, paddingLeft: 12, paddingRight: 12 }}>
          <FormBuilderComponent
            inputs={getGroupByForm(state)}
            onSubmit={handleSubmit}
          />
          {/* <CategoryForm
            all={all}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
          /> */}
        </div>
      </div>
    </PageWrapperComponent>
  );
};

export default EditGroupByComponent;
