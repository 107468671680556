import { API } from "../../constants/api.constants";
import { resetUserTokenAction } from "../../reducers/app.reducer";
import { store } from "../../store";

const {
  CREATE_GROUP_BY,
  EDIT_GROUP_BY,
  DELETE_GROUP_BY,
  GROUP_BY_LIST,
  GROUP_BUY_PRODUCTS,
} = API;
export const getDataApi = async () => {
  return await fetch(`${GROUP_BY_LIST}`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      token: store.getState().app?.token,
    },
  })
    .then((res) => res.json())
    .then((response) => {
      // console.log(response.data);
      if (response.status)
        return {
          status: true,
          data: response.data,
        };
      else {
        return { status: false, message: response.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong, please try again later.",
        error,
      };
    });
};
export const createApi = async (payload) => {
  return await fetch(CREATE_GROUP_BY, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      token: store.getState().app?.token,
    },
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status === true)
        return {
          data: data.data,
          status: data.status,
        };
      else {
        checkForExpiredToken(data.message);
        return { status: false, message: data.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong from login api",
        error,
      };
    });
};
export const editApi = async (id, payload) => {
  return await fetch(EDIT_GROUP_BY + id, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      token: store.getState().app?.token,
    },
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status === true)
        return {
          data: data.data,
          status: data.status,
        };
      else {
        checkForExpiredToken(data.message);
        return { status: false, message: data.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong from login api",
        error,
      };
    });
};
export const checkForExpiredToken = (message) => {
  if (
    message === "Token expired" ||
    message === "Invalid token" ||
    message === "Token refresh"
  ) {
    store.dispatch(resetUserTokenAction());
  }
};

export const deleteDataApi = async (id) => {
  return await fetch(DELETE_GROUP_BY + id, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      token: store.getState().app.token,
    },
    // body: JSON.stringify({ ids }),
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status)
        return {
          status: true,
          data: data.data.list,
          totalCount: data.data.total_count,
        };
      else {
        checkForExpiredToken(data.message);
        return { status: false, message: data.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong, please try again later.",
        error,
      };
    });
};
export const updateProductsApi = async (id, payload) => {
  return await fetch(GROUP_BUY_PRODUCTS + id, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      token: store.getState().app?.token,
    },
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status === true)
        return {
          data: data.data,
          status: data.status,
        };
      else {
        checkForExpiredToken(data.message);
        return { status: false, message: data.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong from login api",
        error,
      };
    });
};
