import React, { useEffect, useState } from "react";

import PageWrapperComponent from "../../components/page-wrapper/page-wrapper.component";
import AccountHeaderComponent from "../../components/account-header/account-header.component";

import { editCustomerApi } from "./customers.service";
import { useLocation, useNavigate } from "react-router-dom";
import CustomerForm from "./customers-form";
import { getCustomerForm } from "./form.components";
import FormBuilderComponent from "../../components/form-builder/form-builder";

const EditCustomerComponent = () => {
  const { state } = useLocation();

  const navigate = useNavigate();

  const gotoScreen = (url) => {
    navigate(url);
  };

  const handleSubmit = async (values) => {
    // console.log(all);

    const resp = await editCustomerApi(state?.id, values);
    console.log(resp.status);
    if (resp.status) {
      gotoScreen("/customers");
    }
  };

  // console.log({ all });

  return (
    <PageWrapperComponent>
      <div className="page-container">
        <AccountHeaderComponent name="Edit Customer" backRoute="/customers" />
        <div style={{ paddingTop: 70, paddingLeft: 12, paddingRight: 12 }}>
          <FormBuilderComponent
            onSubmit={handleSubmit}
            inputs={getCustomerForm(state)}
          />
        </div>
      </div>
    </PageWrapperComponent>
  );
};

export default EditCustomerComponent;
