import React from "react";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft, FaShare } from "react-icons/fa";

const AccountHeaderComponent = ({
  name,
  backRoute = "/",
  shareLink = null,
}) => {
  const navigate = useNavigate();

  const gotoScreen = () => {
    navigate(backRoute);
  };

  const handleShare = () => {
    if (navigator.share) {
      // Web Share API (works on mobile and some desktop browsers)
      navigator
        .share({
          title: "Check out this post!",
          url: shareLink,
        })
        .catch((error) => {
          console.log("Error sharing:", error);
        });
    }
  };

  return (
    <div className="flex account-header">
      <div className="back-button" onClick={gotoScreen}>
        <FaArrowLeft />
      </div>
      <div className="screen-title">
        <p className="no-padding no-margin "> {name} </p>
      </div>
      {shareLink && (
        <div className="header-share" onClick={handleShare}>
          <FaShare color="rgb(221 179 23)" />{" "}
        </div>
      )}
    </div>
  );
};

export default AccountHeaderComponent;
