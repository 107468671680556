import React from "react";
import { isMobile } from "react-device-detect";
import OnlyMobileComponent from "../only-mobile/only-mobile.component";

const PageWrapperComponent = ({ children }) => {
  if (!isMobile) {
    return <OnlyMobileComponent />;
  }

  return <>{children}</>;
};

export default PageWrapperComponent;
