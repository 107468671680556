import React, { useEffect, useState } from "react";

import PageWrapperComponent from "../../components/page-wrapper/page-wrapper.component";
import AccountHeaderComponent from "../../components/account-header/account-header.component";
import { useLocation, useNavigate } from "react-router-dom";
import QuickLinkForm from "./quick-links-form";
import { editApi } from "./quick-links.service";

const EditQuickLinkComponent = () => {
  const { state } = useLocation();

  const navigate = useNavigate();

  const gotoScreen = (url) => {
    navigate(url);
  };
  const [all, setAll] = useState({
    id: "",
    name: "",
    nameError: "",
    status: 1,
    url: "",
  });

  const handleChange = async (name, value) => {
    console.log({ name, value });
    setAll({ ...all, [name]: value });
  };

  const handleSubmit = async () => {
    // console.log(all);

    const resp = await editApi(all?.id, all);
    console.log(resp.status);
    if (resp.status) {
      gotoScreen("/quick-link");
    }
  };
  useEffect(() => {
    setAll(state);
  }, [state]);

  console.log({ all });

  return (
    <PageWrapperComponent>
      <div className="page-container">
        <AccountHeaderComponent
          name="Edit Qiuck link"
          backRoute="/quick-link"
        />
        <div style={{ paddingTop: 10 }}>
          <QuickLinkForm
            all={all}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
          />
        </div>
      </div>
    </PageWrapperComponent>
  );
};

export default EditQuickLinkComponent;
