import React from "react";
import { FaEdit } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { isAdmin } from "../../utils/common";
import PriceComponent from "../price/price";
import PostShareComponent from "../post-share/post-share";
import PageWrapperComponent from "../page-wrapper/page-wrapper.component";

const AppProductsComponent = ({ name, posts }) => {
  const navigate = useNavigate();

  const handleClick = (post) => {
    navigate(`/post/${post.unique_id}`);
  };

  const handleEditPost = (post) => {
    navigate("/post/edit", { state: post });
  };
  return (
    <PageWrapperComponent>
      <div className="app-products">
        <p className="title">{name}</p>
        <div className="grid-container">
          {posts.map((post, i) => (
            <div className="grid-product-item left">
              <div onClick={() => handleClick(post)}>
                {post?.images?.length > 0 && (
                  <img
                    className="grid-product-item-image"
                    src={post?.images[0]?.image}
                  />
                )}

                {!post.images && (
                  <div className="t-a-c">
                    <img
                      className="grid-product-item-image no-image"
                      src={require("./../../assets/icons/no-photos.png")}
                    />
                  </div>
                )}

                <div className="grid-content">
                  <div>
                    <p className="grid-item-text">{post.title}</p>
                    {/* <p className="grid-item-price">
                    <PriceComponent price={post.price} />
                  </p> */}
                  </div>

                  <div style={{ marginTop: 3 }}>
                    <p className="grid-item-location">
                      {post?.customer_details?.name}{" "}
                    </p>
                    <p className="grid-item-location">
                      {post?.customer_details?.phone}
                    </p>
                    <p className="grid-item-location">
                      {post?.customer_details?.address}
                    </p>
                  </div>

                  <div className="grid-item-price">
                    <PriceComponent price={post.price} />
                  </div>
                </div>
              </div>

              <div>
                <PostShareComponent post={post} />
              </div>

              {isAdmin() && (
                <div
                  style={{ marginTop: 30 }}
                  onClick={() => handleEditPost(post)}
                >
                  <p className="no-margin post-edit">
                    <span style={{ marginRight: 5 }}>Edit</span>
                    <FaEdit style={{ marginTop: 2 }} />
                  </p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </PageWrapperComponent>
  );
};

export default AppProductsComponent;
