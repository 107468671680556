import React from "react";

import { getGroupBuyAdminInputs } from "../group-buy.constant";
import LoaderComponent from "../../../components/loader/loader";
import FormBuilderComponent from "../../../components/form-builder/form-builder";
import { joinGroupBuyByAdminAPI } from "../group-buy.service";
import { useNavigate } from "react-router-dom";

const AddMemberByAdmin = ({ user, modalLoading, uniqueId }) => {
  console.log(user);
  const navigate = useNavigate();

  const handleSubmit = async (values) => {
    console.log(values);
    const response = await joinGroupBuyByAdminAPI(uniqueId, values);
    if (response.status) {
      navigate(`/group-buy/${uniqueId}`);
    } else alert(response.message);
  };
  if (modalLoading)
    return (
      <div className="t-a-c" style={{ paddingTop: 50 }}>
        <LoaderComponent />
      </div>
    );

  return (
    <div className="gb-join-container">
      <div className="mt-30">
        <FormBuilderComponent
          inputs={getGroupBuyAdminInputs({})}
          onSubmit={handleSubmit}
        />
      </div>
    </div>
  );
};

export default AddMemberByAdmin;
