import React from "react";

const OnlyMobileComponent = () => {
  return (
    <div className="only-mobile-content">
      <p className="t-a-c"> Link works only in mobile </p>
    </div>
  );
};

export default OnlyMobileComponent;
