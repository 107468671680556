import React from "react";
import { FaPhone, FaWhatsapp } from "react-icons/fa";
import { APP_URL } from "../../constants/api.constants";
import LinkShareButton from "./link-share-button";

const PostShareComponent = ({ post }) => {
  return (
    <div className="contact-seller-mode">
      <span
        className="contact-button contact-mode"
        style={{
          //   marginLeft: "30px",
          backgroundColor: "#65ce40 ",
        }}
      >
        <a
          href={`https://wa.me/+91${post.customer_details?.phone.replace(
            /\D/g,
            ""
          )}?text=${encodeURIComponent(
            "Hi, I would like to know the price of this post."
          )} https://vagbay.com/post/${post.unique_id}`}
        >
          <FaWhatsapp style={{ width: 20, height: 20 }} color="white" />
        </a>
      </span>

      <span
        className="contact-button contact-mode"
        style={{ backgroundColor: "rgb(78 190 255)" }}
      >
        <a href={`tel:${post?.customer_details?.phone}`}>
          <FaPhone style={{ width: 20, height: 20 }} color="white" />
        </a>
      </span>

      <LinkShareButton url={APP_URL + "post/" + post.unique_id} />
    </div>
  );
};

export default PostShareComponent;
