import { store } from "../store";

export const isAdmin = () => {
  const { user } = store.getState().app;

  if (user && user.role_name && user.role_name === "Admin") return true;

  return false;
};

export const groupBy = (array, key) => {
  return Array.from(
    array
      .reduce((map, item) => {
        const keyValue = item[key];
        map.set(keyValue, { ...map.get(keyValue), ...item });
        return map;
      }, new Map())
      .values()
  );
};

export const generateSlug = (inputString) => {
  return inputString
    .toLowerCase() // Convert to lowercase
    .trim() // Remove leading and trailing spaces
    .replace(/[\s\W-]+/g, "-") // Replace spaces and non-word characters with a dash
    .replace(/^-+|-+$/g, ""); // Remove leading and trailing dashes
};
