import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import { getDataApi } from "../../screens/post/post.service";
import AppProductsComponent from "../app-products/app-products.component";
import LoaderComponent from "../loader/loader";
import AppHeaderComponent from "../app-header/app-header.component";
import AccountHeaderComponent from "../account-header/account-header.component";
import PageWrapperComponent from "../page-wrapper/page-wrapper.component";
import { APP_URL } from "../../constants/api.constants";

const ViewCategoryProductsComponent = () => {
  const { id, name } = useParams();
  console.log({ id, name });
  const navigate = useNavigate();
  const [all, setAll] = useState({
    loading: true,
    posts: [],
    search: "",
  });

  let params = {
    orderBy: "created_at",
    sortBy: "desc",
    skip: 0,
    limit: 50,
  };
  const onLoad = () => {
    getData(params);
  };

  const getData = async (params) => {
    let filters = [];
    if (id) {
      filters.push(`category_id=${id}`);
    }

    const filtersString = filters.join("&");

    const data = await getDataApi({
      ...params,
      filters: filtersString,
      limit: 100,
    });

    if (data.status) {
      console.log(data);
      setAll({
        ...all,
        loading: false,
        posts: data.data,
        search: "",
      });
    }
  };
  useEffect(() => {
    onLoad();
  }, [id]);

  // Inside your component's return statement
  const handleSearch = (value) => {
    console.log(value);
    if (value) {
      const results = all?.posts.filter((post) =>
        post.title.toLowerCase().includes(value.toLowerCase())
      );

      setAll({
        search: value,
        posts: results,
      });
    } else {
      setAll({
        ...all,
        search: "",
      });
      onLoad();
    }
  };
  // console.log(state, all?.posts);
  return (
    <PageWrapperComponent>
      <div className="app-container bg">
        <AccountHeaderComponent
          name={name + " Parts"}
          backRoute="/"
          shareLink={APP_URL + "view-category/" + id + "/" + name}
        />
        <div className="app-search" style={{ paddingTop: 75 }}>
          <input
            className="search-input"
            type="text"
            name="search"
            id="search"
            value={all?.search}
            onChange={(e) => handleSearch(e.target.value)}
            placeholder="Type here to search."
          />
        </div>
        {all?.loading ? (
          <div className="t-a-c" style={{ paddingTop: 125 }}>
            <LoaderComponent
              path={require("./../../assets/icons/loading-gif.gif")}
            />
          </div>
        ) : (
          <div style={{ paddingTop: 5 }}>
            <AppProductsComponent name={""} posts={all?.posts} />
          </div>
        )}
      </div>
    </PageWrapperComponent>
  );
};

export default ViewCategoryProductsComponent;
