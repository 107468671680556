import React from "react";

import PageWrapperComponent from "../../components/page-wrapper/page-wrapper.component";
import AccountHeaderComponent from "../../components/account-header/account-header.component";
import { createCategoryAPI } from "./category.service";
import { useNavigate } from "react-router-dom";
import FormBuilderComponent from "../../components/form-builder/form-builder";
import { getCategoryForm } from "./form.constant";

const AddCategoryComponent = () => {
  const navigate = useNavigate();

  const gotoScreen = (url) => {
    navigate(url);
  };

  const handleSubmit = async (values) => {
    const resp = await createCategoryAPI(values);
    if (resp.status) {
      gotoScreen("/category"); // Use the gotoScreen function to navigate
    }
  };

  return (
    <PageWrapperComponent>
      <div className="page-container">
        <AccountHeaderComponent name="Add Category" backRoute="/category" />

        <div>
          <div style={{ paddingTop: 70, paddingLeft: 12, paddingRight: 12 }}>
            <FormBuilderComponent
              onSubmit={handleSubmit}
              inputs={getCategoryForm({})}
            />
          </div>
        </div>
      </div>
    </PageWrapperComponent>
  );
};

export default AddCategoryComponent;
