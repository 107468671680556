import { API } from "../../constants/api.constants";

const { GET_POST_BY_ID, GET_RELATED_POST_BY_ID } = API;

export const getPostDataAPI = async (id) => {
  return await fetch(`${GET_POST_BY_ID + id}/id`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((response) => {
      if (response.status)
        return {
          status: true,
          data: response.data,
        };
      else {
        return { status: false, message: response.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong, please try again later.",
        error,
      };
    });
};

export const getRelatedPostsDataAPI = async (id) => {
  return await fetch(`${GET_RELATED_POST_BY_ID + id}`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((response) => {
      if (response.status)
        return {
          status: true,
          data: response.data,
        };
      else {
        return { status: false, message: response.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong, please try again later.",
        error,
      };
    });
};
