import React, { useState } from "react";

const FormInputDateComponent = ({
  label,
  id,
  name,
  value,
  placeholder,
  error,
  onChange,
  isVertical,
}) => {
  // console.log(value);

  const handleChange = (e) => {
    if (onChange) onChange(name, e.target.value);
  };

  return (
    <div className="form-input-container mb-10">
      {" "}
      <div className="">
        <div className=" mb-10">
          <label>
            {label && <p className="no-margin form-input-label"> {label} </p>}
          </label>
        </div>
        <div className="form-input-container">
          <input
            className={"search-input form-input-date"}
            type={"date"}
            name={name}
            id={id}
            value={value}
            placeholder={placeholder}
            onChange={handleChange}
          />
          {error && <div className="invalid-feedback">{error}</div>}
        </div>
      </div>
    </div>
  );
};

export default FormInputDateComponent;
