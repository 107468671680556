import { API } from "../../constants/api.constants";

const { LOGIN } = API;

export const loginAPI = async (payload) => {
  return await fetch(LOGIN, {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status === true)
        return {
          token: data.data.token,
          user: data.data.user,
          status: data.status,
        };
      else return { status: false, message: data.message };
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong from login api",
        error,
      };
    });
};
