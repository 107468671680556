import React, { useEffect, useState } from "react";
import CustomerRegistration from "../customer-registration";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { setUserTokenAction } from "../../../reducers/app.reducer";
import {
  getGroupBuyDataAPI,
  joinGroupBuyAPI,
  memberRegistrationAPI,
  memberRegistrationVerifyAPI,
} from "../group-buy.service";
import PageWrapperComponent from "../../../components/page-wrapper/page-wrapper.component";
import AccountHeaderComponent from "../../../components/account-header/account-header.component";
import AddMemberByAdmin from "../payment/admin-add-members";

const GroupBuyJoinComponent = () => {
  const { user } = useSelector((state) => state.app);
  const { uniqueId } = useParams();
  const [all, setAll] = useState({
    groupBuy: null,
    loading: true,
    showJoinModal: false,
    memberDetails: null,
    joinError: "",
    modalLoading: false,
  });
  const navigate = useNavigate();

  const { groupBuy, loading, showJoinModal, memberDetails, modalLoading } = all;

  const [resendTimer, setResendTimer] = useState(60);
  const dispatch = useDispatch();

  const onLoad = async () => {
    const res = await getGroupBuyDataAPI(uniqueId);

    console.log({ res });

    if (res.status) {
      setAll({ loading: false, groupBuy: res.data });
    } else {
      setAll({ loading: false });
      alert(res.message);
    }
  };

  const handleJoin = async () => {
    const joinRes = await joinGroupBuyAPI(uniqueId);

    console.log({ joinRes });
    if (joinRes.status) {
      navigate(`/group-buy/${uniqueId}`);
    } else alert(joinRes.message);

    setAll({ ...all, showJoinModal: false, modalLoading: false });
  };

  const handleMemberJoin = async (values) => {
    setAll({ ...all, modalLoading: true });
    const res = await memberRegistrationAPI(values);

    console.log({ res });
    if (res.status) {
      setAll({ ...all, memberDetails: values, modalLoading: false });
      setResendTimer(60);
    } else {
      setAll({ ...all, modalLoading: false });
      alert(res.message);
    }
  };

  const handleResendOTP = async () => {
    const res = await memberRegistrationAPI(memberDetails);

    console.log({ res });
    if (res.status) {
      setResendTimer(60);
    }
  };

  const handleMemberVerify = async (values) => {
    setAll({ ...all, modalLoading: true });
    console.log({ values });
    const res = await memberRegistrationVerifyAPI({
      ...values,
      phone: memberDetails.phone,
    });

    if (res.status) {
      dispatch(
        setUserTokenAction({
          ...res.data,
          user: { ...res.data.user, type: "customer" },
        })
      );
      handleJoin();
    } else {
      alert(res.message);
    }
  };

  useEffect(() => {
    onLoad();
  }, []);

  useEffect(() => {
    let timer;
    if (memberDetails && resendTimer > 0) {
      timer = setInterval(() => {
        setResendTimer((prevSeconds) => prevSeconds - 1);
      }, 1000);
    }

    // Cleanup the interval when the component unmounts or timer stops
    return () => clearInterval(timer);
  }, [memberDetails, resendTimer]);

  // logged in customer or admin
  let loggedInAdminOrUser = false;
  let currentCustomerJoinedId = null;
  if (groupBuy && user && user.role_name === "Admin" && user.customer) {
    loggedInAdminOrUser = true;
    currentCustomerJoinedId = groupBuy.total_members.find(
      (member) => member.customer_id === user.customer.id
    );
  }

  if (groupBuy && user && user.type === "customer") {
    loggedInAdminOrUser = true;
    currentCustomerJoinedId = groupBuy.total_members.find(
      (member) => member.customer_id === user.id
    );
  }

  // console.log(user);

  return (
    <PageWrapperComponent>
      <AccountHeaderComponent name={`Join ${groupBuy?.name}`} backRoute="/" />

      {user?.role_name === "Admin" ? (
        <AddMemberByAdmin
          modalLoading={modalLoading}
          user={user}
          uniqueId={uniqueId}
        />
      ) : (
        <div style={{ paddingTop: 40 }}>
          <CustomerRegistration
            modalLoading={modalLoading}
            user={user}
            resendTimer={resendTimer}
            memberDetails={memberDetails}
            loggedInAdminOrUser={loggedInAdminOrUser}
            handleJoin={handleJoin}
            handleMemberVerify={handleMemberVerify}
            handleMemberJoin={handleMemberJoin}
            handleResendOTP={handleResendOTP}
          />
        </div>
      )}
    </PageWrapperComponent>
  );
};

export default GroupBuyJoinComponent;
