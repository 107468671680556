import React from "react";
import FormInputTextComponent from "../../components/form-inputs/form-input-text/form-input-text.component";
import ButtonComponent from "../../components/button/button.component";
import FormInputTextAreaComponent from "../../components/form-inputs/form-input-text/form-input-textarea.component";

const CustomerForm = ({ all, handleChange, handleSubmit }) => {
  return (
    <div className="account-content">
      <div className="container">
        <FormInputTextComponent
          name={"name"}
          label={"Name"}
          onChange={handleChange}
          placeholder={"Enter the customer name"}
          value={all?.name}
        />
      </div>

      <div className="container mt-10">
        <FormInputTextComponent
          name={"phone"}
          label={"Phone"}
          onChange={handleChange}
          placeholder={"Enter the phone number"}
          value={all?.phone}
        />
      </div>
      <div className="container mt-10">
        <FormInputTextAreaComponent
          name={"address"}
          label={"Address"}
          onChange={handleChange}
          placeholder={"Enter the address"}
          value={all?.address}
        />
      </div>
      <div className="container mt-10">
        <FormInputTextComponent
          name={"location"}
          label={"Location"}
          onChange={handleChange}
          placeholder={"Enter the location"}
          value={all?.location}
        />
      </div>

      <div className="container mt-10">
        <ButtonComponent text="Submit" onClick={handleSubmit} />
      </div>
    </div>
  );
};

export default CustomerForm;
