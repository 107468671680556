import React from "react";
import { FaShare } from "react-icons/fa";

const LinkShareButton = ({ url }) => {
  const handleShare = () => {
    if (navigator.share) {
      // Web Share API (works on mobile and some desktop browsers)
      navigator
        .share({
          title: "Check out this post!",
          url: url,
        })
        .catch((error) => {
          console.log("Error sharing:", error);
        });
    }
  };

  return (
    <>
      <span
        className="contact-button contact-mode"
        style={{ backgroundColor: "rgb(221 179 23)" }}
      >
        <a onClick={handleShare}>
          <FaShare style={{ width: 20, height: 20 }} color="white" />
        </a>
      </span>
    </>
  );
};

export default LinkShareButton;
