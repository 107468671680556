import { useLocation, useNavigate } from "react-router-dom";
import PageWrapperComponent from "../../components/page-wrapper/page-wrapper.component";
import AccountHeaderComponent from "../../components/account-header/account-header.component";
import { useEffect, useState } from "react";
import {
  faEdit,
  faPlus,
  faPlusCircle,
  faPlusSquare,
  faTrash,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormInputTextComponent from "../../components/form-inputs/form-input-text/form-input-text.component";
import {
  getGroupBuyProductsForm,
  getGroupProductsByForm,
} from "./form.constant";
import FormBuilderComponent from "../../components/form-builder/form-builder";
import { updateProductsApi } from "./group-by.service";
import ButtonComponent from "../../components/button/button.component";
import { v4 as uuidv4 } from "uuid";
import BottomSheetComponent from "../../components/bottom-sheet/bottom-sheet";

const AddProductsGroupByComponent = () => {
  const { state } = useLocation();
  const [all, setAll] = useState({
    loading: true,
    openModel: false,
    editRow: false,
    addRow: false,
  });
  const { loading, editRow, openModel, addRow } = all;
  const [products, setProducts] = useState([]);
  const [singleProduct, setSingleProduct] = useState({
    index: "",
    value: "",
  });

  const navigate = useNavigate();
  const handleAdd = () => {
    setAll({ ...all, openModel: true, addRow: true });
  };
  const handleDeleteClick = (index) => {
    const updatedProducts = products.filter((_, i) => i !== index);
    setProducts(updatedProducts);
  };
  //   console.log(state);
  useEffect(() => {
    setProducts(state?.products || []);
    // console.log(state?.products);
  }, [state]);

  const handleSubmit = async (value) => {
    let payload = {
      products: products,
    };
    const response = await updateProductsApi(state?.unique_id, payload);

    if (response.status) {
      navigate("/group-buy");
    }

    console.log(response);
  };
  const toggleBottomSheet = (value) => {
    setAll({ ...all, openModel: false, editRow: false, addRow: false });
  };

  const handleAddrow = (values) => {
    console.log(products);

    let rowValues = {
      ...values,
      id: uuidv4(),
    };
    console.log(rowValues);

    setProducts([...products, rowValues]);
    toggleBottomSheet();
  };
  const handleEdit = (index, item) => {
    setAll({ ...all, openModel: true, editRow: true });

    setSingleProduct({ ...singleProduct, index: index, value: item });
    // console.log(item);
  };
  // console.log(singleProduct?.value);

  const handleEditrow = (values) => {
    const idx = parseInt(singleProduct?.index);
    console.log(idx);
    console.log(values);

    const updatedProducts = [...products];
    updatedProducts[idx] = {
      ...updatedProducts[idx],
      name: values?.name,
      price: values?.price,

      id: values?.id || uuidv4(),
    };
    // console.log(updatedProducts);
    setProducts(updatedProducts);

    toggleBottomSheet();
  };
  console.log(products);

  return (
    <PageWrapperComponent>
      <div className="page-container">
        <AccountHeaderComponent name="Add Group Buy" backRoute="/group-buy" />

        <div>
          {/* <div>
            <span>
              <img src={state?.image}></img>
              {/* <span>name: {state?.name}</span> */}
          {/* </span> */}
          {/* </div> */}

          <div style={{ paddingTop: 70, paddingLeft: 12, paddingRight: 12 }}>
            <div className="gb-s-header">
              <div className="flex">
                <div style={{ paddingRight: "20px" }}>
                  <img
                    src={
                      state?.image
                        ? state?.image
                        : require("./../../assets/icons/no-photos.png")
                    }
                  />
                </div>
                <div className="mt-30">
                  {/* <p className="gb-s-members-count">
                Memebers Count : {groupBuy?.total_members?.length}
              </p> */}
                  {/* <p className="gb-s-admin"> */}
                  <span className="gb-s-admin"> Group Name : </span>
                  <span className="gb-s-title"> {state?.name}</span>
                  {/* </p> */}
                  <p className="gb-s-admin">
                    Admin : {state?.group_admin?.name}
                  </p>
                  <p className="gb-s-admin">
                    Mobile : {state?.group_admin?.phone}
                  </p>
                  <p className="gb-s-admin">
                    Description : {state.description}
                  </p>
                </div>
              </div>
              {/* <div className="gb-s-desc">{state.description}</div> */}
            </div>
            {/* <FormBuilderComponent
              onSubmit={handleSubmit}
              inputs={getGroupProductsByForm(state)}
            /> */}
            <div className=" mt-30">
              <div>
                {products?.length > 0 ? (
                  products?.map((product, index) => (
                    <div
                      key={product.id}
                      style={{
                        border: "1px solid #ccc",
                        borderRadius: "10px",
                        padding: "12px",
                        display: "flex",
                        alignItems: "center",
                        boxSizing: "border-box",
                        backgroundColor: "#fff",
                        marginTop: "10px",
                      }}
                    >
                      <div
                        style={{
                          marginLeft: "15px",
                          flexGrow: 1,
                          textAlign: "left",
                          fontWeight: "normal",
                        }}
                      >
                        <span>{product?.name}</span>
                        <p
                          className=" p-color "
                          style={{
                            margin: " 5px 0px 0px 2px",
                          }}
                        >
                          {" "}
                          ₹ {product?.price}
                        </p>
                      </div>
                      <span style={{ marginLeft: "15px", textAlign: "right" }}>
                        <FontAwesomeIcon
                          icon={faTrashCan}
                          style={{ cursor: "pointer", color: "#dc3545" }}
                          onClick={() => handleDeleteClick(index)}
                        />
                      </span>
                      <span style={{ marginLeft: "15px", textAlign: "right" }}>
                        <FontAwesomeIcon
                          icon={faEdit}
                          style={{ cursor: "pointer", color: "#7c77fb" }}
                          onClick={() => handleEdit(index, product)}
                        />

                        {index == products.length - 1 && (
                          <span
                            style={{ marginLeft: "18px", textAlign: "right" }}
                          >
                            <FontAwesomeIcon
                              icon={faPlusCircle}
                              style={{
                                cursor: "pointer",
                                color: "#fe7506",
                                // height: "18px",
                              }}
                              onClick={() => handleAdd()}
                            />
                          </span>
                        )}
                      </span>
                    </div>
                  ))
                ) : (
                  <p>
                    {" "}
                    <div className="container mt-10">
                      <ButtonComponent
                        text="Add Products "
                        onClick={() => handleAdd()}
                      />
                    </div>
                  </p>
                )}

                {products?.length > 0 && (
                  <div className="container mt-10">
                    <ButtonComponent text="Submit" onClick={handleSubmit} />
                  </div>
                )}
              </div>
              <BottomSheetComponent
                height={0.5}
                open={openModel}
                title={addRow == true ? "Add Product !" : "Edit Product"}
                toggleBottomSheet={toggleBottomSheet}
                content={
                  <div className="gb-join-container">
                    {addRow && (
                      <FormBuilderComponent
                        inputs={getGroupBuyProductsForm({})}
                        onSubmit={handleAddrow}
                      />
                    )}

                    {editRow && (
                      <FormBuilderComponent
                        inputs={getGroupBuyProductsForm(singleProduct?.value)}
                        onSubmit={handleEditrow}
                      />
                    )}
                  </div>
                }
              />
            </div>{" "}
          </div>
        </div>
      </div>
    </PageWrapperComponent>
  );
};

export default AddProductsGroupByComponent;
