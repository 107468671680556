import React, { useState } from "react";
import LoaderComponent from "../../loader/loader";

const FormInputFileUploadComponent = ({
  label,
  name,
  id,
  value,
  placeholder,
  classnames,
  icon,
  error,
  onChange,
  isRequired = false,
}) => {
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setLoading(true); // Start loading
    console.log({ files: e.target.files });

    if (onChange) {
      onChange(name, e.target.files[0]);
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  };

  return (
    <div className={`form-input-container ${error ? "error" : ""}`}>
      {label && (
        <p className="no-margin form-input-label">
          {label} <span className="color-red">{isRequired ? "*" : ""}</span>
        </p>
      )}
      <div style={{}}>
        <div style={{ display: "flex" }}>
          <div style={{ width: "80%", paddingLeft: 9 }}>
            <input
              className={classnames || "form-input-file-upload"}
              type={"file"}
              name={name}
              id={id}
              // value={value}
              placeholder={placeholder}
              onChange={handleChange}
            />
          </div>

          {loading && (
            <div style={{ marginTop: 5, position: "relative", marginLeft: 11 }}>
              <div style={{ width: 25, float: "left" }}>
                <img
                  className="loader-image"
                  src={require("../../../assets/icons/loading-gif.gif")}
                />
              </div>
            </div>
          )}

          <div className="mt-8">
            {value && (
              <img
                src={value}
                alt="Post Preview"
                style={{
                  width: "50px",
                  borderRadius: "10px",
                  height: "35px",
                }}
              />
            )}
          </div>
        </div>

        {error && <span className="form-input-error"> {error} </span>}

        {icon && <span className="form-input-icon"> {icon} </span>}
      </div>
    </div>
  );
};

export default FormInputFileUploadComponent;
