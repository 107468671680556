import React from "react";
import FormInputTextComponent from "../../components/form-inputs/form-input-text/form-input-text.component";
import ButtonComponent from "../../components/button/button.component";
import FormInputSelectComponent from "../../components/form-inputs/form-input-select/form-input-select.component";
import FormInputFileUploadComponent from "../../components/form-inputs/form-file-upload/form-file-upload.component";
import FormInputTextAreaComponent from "../../components/form-inputs/form-input-text/form-input-textarea.component";

const QuickLinkForm = ({ all, handleChange, handleSubmit }) => {
  console.log(all?.url);

  return (
    <div className="account-content">
      <div className="container">
        <FormInputTextComponent
          name={"name"}
          label={"Name"}
          onChange={handleChange}
          placeholder={"Enter the Link name"}
          value={all?.name}
        />
      </div>
      <div className="container mt-10">
        <FormInputTextAreaComponent
          label={"URL"}
          name={"url"}
          onChange={handleChange}
          placeholder={"Enter the Url "}
          value={all?.url}
        />
      </div>
      <div className="container mt-10">
        <FormInputSelectComponent
          label={"Status"}
          id={"status"}
          name={"status"}
          placeholder={"Select status"}
          onChange={handleChange}
          options={[
            { name: "Select the status", value: "" },
            { name: "Enabled", value: 1 },
            { name: "Disabled", value: 2 },
          ]}
          value={all?.status}
        />
      </div>

      <div className="container mt-10">
        <ButtonComponent text="Submit" onClick={handleSubmit} />
      </div>
    </div>
  );
};

export default QuickLinkForm;
