import React, { useState } from "react";
import PageWrapperComponent from "../../components/page-wrapper/page-wrapper.component";
import "./login.css";
import FormInputTextComponent from "../../components/form-inputs/form-input-text/form-input-text.component";
import ButtonComponent from "../../components/button/button.component";
import { FaArrowLeft, FaEye, FaEyeSlash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { loginAPI } from "./login.service";
import { setUserTokenAction } from "../../reducers/app.reducer";
import { useDispatch } from "react-redux";

const LoginComponent = () => {
  const [all, setAll] = useState({
    username: "",
    password: "",
    showPassword: false,
    usernameError: "",
    passwordError: "",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handlePasswordIconClick = () => {
    setAll({ ...all, showPassword: !all.showPassword });
  };

  const handleChange = (name, value) => {
    setAll({ ...all, [name]: value, [`${name}Error`]: "" });
  };

  const handleLogin = async () => {
    if (all.username.trim() === "") {
      setAll({ ...all, usernameError: "Mobile cannot be empty" });
      return;
    }

    if (all.password.trim() === "") {
      setAll({ ...all, passwordError: "Password cannot be empty" });
      return;
    }

    const resp = await loginAPI(all);

    if (resp.status) {
      dispatch(setUserTokenAction(resp));
      navigate("/account");
    }

    console.log({ resp });
  };

  return (
    <PageWrapperComponent>
      <div className="login-container">
        <div style={{ marginTop: 15 }} onClick={() => navigate("/")}>
          <FaArrowLeft />
        </div>
        <div className="image-container">
          <img src={require("./../../assets/icons/login-car2.png")} />
        </div>

        <p className="login-title"> Welcome Back ! </p>

        <div style={{ marginTop: 20 }}>
          <FormInputTextComponent
            type="number"
            name={"username"}
            id={"username"}
            value={all.username}
            error={all.usernameError}
            placeholder={"Enter the mobile number"}
            onChange={handleChange}
          />
        </div>

        <div style={{ marginTop: 20 }}>
          <FormInputTextComponent
            type={all.showPassword ? "text" : "password"}
            name={"password"}
            id={"password"}
            value={all.password}
            error={all.passwordError}
            placeholder={"Enter the password"}
            onChange={handleChange}
            icon={
              <div onClick={handlePasswordIconClick}>
                {all.showPassword && <FaEye />}
                {!all.showPassword && <FaEyeSlash />}
              </div>
            }
          />
        </div>

        <div>
          <ButtonComponent text={"SUBMIT"} onClick={handleLogin} />
        </div>
      </div>
    </PageWrapperComponent>
  );
};

export default LoginComponent;
