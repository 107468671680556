import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Slider from "react-slick"; // Import the slider component
import AccountHeaderComponent from "../account-header/account-header.component";
import { formatDate } from "../../utils/date.util";
import LoaderComponent from "../loader/loader";
import PriceComponent from "../price/price";

import PostShareComponent from "../post-share/post-share";
import {
  getPostDataAPI,
  getRelatedPostsDataAPI,
} from "../../screens/view-post/post.service";
import EmptyBoxComponent from "../empty-box/empty-box";

const ViewProductsComponent = () => {
  const { state } = useLocation();
  const params = useParams();

  const [activePost, setData] = useState();
  const [allPost, setAllPostData] = useState([]);
  const [loading, setLoading] = useState(true);

  const { uniqueId } = params;

  const navigate = useNavigate();
  const onLoad = async () => {
    const resp = await getPostDataAPI(uniqueId);

    if (resp.status) {
      setData(resp.data);

      const relatedPostsRes = await getRelatedPostsDataAPI(uniqueId);

      if (relatedPostsRes.status) setAllPostData(relatedPostsRes.data);
    }

    setLoading(false);
  };
  useEffect(() => {
    onLoad();
  }, [params]);

  const handleClick = (post) => {
    setLoading(true);

    // console.log(posts);
    // navigate("/view-post", { state: post });
    navigate(`/post/${post.unique_id}`);
  };

  const handleCustomersClick = async (post) => {
    // console.log(post);

    if (post?.customer_unique_id)
      navigate(`/view-customer-post/${post?.customer_unique_id}`, {
        state: post?.customer_details,
      });
  };
  // console.log({ activePost, allPost });
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  if (loading)
    return (
      <div className="t-a-c" style={{ paddingTop: 125 }}>
        <LoaderComponent
          path={require("./../../assets/icons/loading-gif.gif")}
        />
      </div>
    );
  let emptyPost = false;
  if (!activePost || (activePost && activePost.is_deleted === 2))
    emptyPost = true;

  return (
    <div className="view-product">
      <AccountHeaderComponent name="Home" backRoute="/" />{" "}
      <div style={{ paddingTop: 5 }}>
        {emptyPost && (
          <div className="container t-a-c" style={{ marginTop: 70 }}>
            <EmptyBoxComponent message="The post you're looking for has been deleted" />
          </div>
        )}

        {activePost && activePost.is_deleted != 2 && (
          <div style={{ padding: "20px", paddingTop: "55px" }}>
            {activePost.images && activePost.images.length > 0 ? (
              <Slider {...settings}>
                {activePost.images.map((img, index) => (
                  <div key={index}>
                    <img
                      className="post-sliders"
                      src={img.image}
                      alt={`Post Image ${index + 1}`}
                    />
                  </div>
                ))}
              </Slider>
            ) : (
              <img
                className="grid-product-item-image"
                style={{ width: "60%", marginLeft: "20%" }}
                src={require("./../../assets/icons/no-photos.png")}
                alt="No Images Available"
              />
            )}
            <div
              className="post-price-container flex"
              style={{ justifyContent: "space-between" }}
            >
              <span>
                <p className="post-category ">{activePost.category}</p>
              </span>
              <span className="post-price">
                <p className="no-margin" style={{ marginTop: 14 }}>
                  <PriceComponent price={activePost.price} />
                </p>
              </span>
            </div>
            <h3 className="f-bold no-margin">{activePost.title}</h3>
            <div
              style={{ marginTop: 10, justifyContent: "space-between" }}
              className="flex"
            >
              <div className="date">Location :</div>
              <div style={{ marginTop: 5 }}>
                {activePost.customer_details.location}
              </div>
            </div>
            <div
              style={{ marginTop: 10, justifyContent: "space-between" }}
              className="flex"
            >
              <div className="date">Published :</div>
              <div>{formatDate(activePost.created_at)}</div>
            </div>

            <PostShareComponent post={activePost} />

            <div className="mt-30">
              <div
                style={{
                  marginTop: "30px",
                  borderBottom: " 1px solid #b4b4b7",
                }}
              ></div>
              {activePost.description && (
                <div style={{ marginBottom: 10 }}>
                  <h2 style={{ margin: "13px 6px 6px 0px" }} className="f-bold">
                    Description
                  </h2>
                  <div className=" post-desc">{activePost.description}</div>
                </div>
              )}

              <div className="">
                <div className="">
                  <div
                    style={{
                      fontSize: 18,
                      marginTop: 15,
                      marginBottom: 15,
                    }}
                    className="f-bold t-a-c"
                  >
                    Seller Information
                  </div>
                  <div
                    className=" seller-information"
                    onClick={() => handleCustomersClick(activePost)}
                  >
                    <span className="contact">
                      <img
                        className="contact-profile"
                        src={require("./../../assets/icons/profile.png")}
                        alt="Profile"
                      />

                      <div className="contact-details">
                        <h2>{activePost?.customer_details?.name}</h2>
                        <h2>{activePost?.customer_details?.phone}</h2>
                      </div>
                    </span>
                  </div>
                </div>

                <div className="t-a-c">
                  <div className="">
                    <h2 className="f-bold">Related Ads</h2>

                    <div className="grid-container">
                      {allPost.map((post, i) => (
                        <div
                          className="grid-product-item left"
                          onClick={() => handleClick(post)}
                        >
                          <img
                            className="grid-product-item-image"
                            src={
                              post?.images && post.images.length > 0
                                ? post.images[0].image
                                : require("./../../assets/icons/no-photos.png")
                            }
                            alt={post?.title || "Product Image"}
                          />
                          <div className="grid-content">
                            <p className="grid-item-text">{post.title}</p>
                            <p className="grid-item-location">
                              {post?.customer_details?.name},{" "}
                              {post?.customer_details?.phone}
                            </p>
                          </div>

                          <div className="grid-item-price">
                            <PriceComponent price={post.price} />
                          </div>

                          <PostShareComponent post={post} />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewProductsComponent;
