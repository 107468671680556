import React, { useEffect, useState } from "react";

import PageWrapperComponent from "../../components/page-wrapper/page-wrapper.component";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AccountHeaderComponent from "../../components/account-header/account-header.component";
import Swal from "sweetalert2";
import { resetUserTokenAction } from "../../reducers/app.reducer";
import { getAdminInfoAPI } from "./account.service";
import Admin from "./admin";
import Customer from "./customer";

const AccountComponent = () => {
  const { user } = useSelector((state) => state.app);
  const [all, setAll] = useState({ total_category: 0, total_post: 0 });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const gotoScreen = (url = "/") => {
    navigate(url);
  };

  const handleLogoutConfirm = () => {
    dispatch(resetUserTokenAction());
    gotoScreen("/");
  };

  const handleLogout = () => {
    Swal.fire({
      title: "Logout !",
      text: "Are you sure to logout?",
      icon: "info",
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
      showCancelButton: true,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        handleLogoutConfirm();
      } else if (result.isDismissed) {
        console.log("Dismissed ");
      }
    });
  };

  const onLoad = async () => {
    const resp = await getAdminInfoAPI();
    console.log({ resp });
    if (resp.status)
      setAll({
        total_category: resp.data.category_count,
        total_post: resp.data.post_count,
      });
  };

  useEffect(() => {
    onLoad();
  }, []);

  return (
    <PageWrapperComponent>
      <div className="page-container">
        <AccountHeaderComponent name="My Account" backRoute="/" />

        <div className="account-content">
          {user.role_name === "Admin" && (
            <Admin
              user={user}
              all={all}
              handleLogout={handleLogout}
              gotoScreen={gotoScreen}
            />
          )}

          {user.type === "customer" && (
            <Customer user={user} handleLogout={handleLogout} />
          )}
        </div>
      </div>
    </PageWrapperComponent>
  );
};

export default AccountComponent;
